import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useField } from 'formik';
import styled from 'styled-components';

import * as Styled from '../input.styles';

import ClearButton from '../../common/ClearButton';
import Counter from '../../common/Counter';
import Error from '../../common/Error';
import TextareaAutosize from './textarea';
import { TextareaFieldProps, TextareaProps } from './types/Textarea.types';

const StyledTextarea = styled(TextareaAutosize)<{ label: string }>(
  ({ label }) => Styled.inputStyles({ label }),
);

const Textarea: React.FC<TextareaProps> = React.memo(
  ({
    placeholder = ' ',
    name,
    label = '',
    disabled = false,
    readOnly = false,
    className,
    minRows = 4,
    maxRows,
    maxLength,
    value,
    onChange: handleChange,
    symbolCounter = false,
    clearable = false,
    parentClassName,
    error,
    touched,
  }) => {
    const ref = useRef<HTMLTextAreaElement>();

    const [cleared, setCleared] = useState(false);

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (handleChange) handleChange(event.target.value);
    };

    const onClear = (data: { target: { value: string } }) => {
      onChange(data as React.ChangeEvent<HTMLTextAreaElement>);
      setCleared(true);
      setTimeout(() => setCleared(false), 0);
    };

    return (
      <Styled.Container
        {...{
          className: cn('input-container textarea-container', parentClassName),
        }}
      >
        <Styled.Wrapper {...{ disabled, className: cn('textarea', className) }}>
          <StyledTextarea
            {...{
              ref: ref as React.RefObject<HTMLTextAreaElement>,
              placeholder,
              name,
              label,
              disabled,
              readOnly,
              maxLength,
              minRows,
              maxRows,
              value,
              onChange,
              clearable,
            }}
          />
          <Styled.Label>{label}</Styled.Label>
          {clearable && <ClearButton {...{ inputRef: ref, onClear }} />}
        </Styled.Wrapper>
        <Styled.BottomWrapper>
          <Error {...{ error, touched }} />
          {symbolCounter && maxLength && (
            <Counter {...{ inputRef: ref, maxLength, cleared }} />
          )}
        </Styled.BottomWrapper>
      </Styled.Container>
    );
  },
);

const TextareaField: React.FC<TextareaFieldProps> = React.memo((props) => {
  const [{ value: initialValue }, { error, touched }, { setValue }] =
    useField<string>(props.name);

  const [value, onChange] = useState(initialValue);

  useEffect(() => {
    setValue(value, true);
  }, [value]);

  return (
    <Textarea
      {...{
        ...props,
        value,
        onChange,
        error,
        touched,
      }}
    />
  );
});

export { TextareaField };

export default Textarea;
