import { useSupportMessage } from '#hooks';

import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import Form from './containers/Form';

export default () => {
  const [data, { generalLoading }] = useSupportMessage();

  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Обращение',
          underlineVariant: HeadUnderlineVariant.Tertiary,
        }}
      />
      <Form data={data} />
    </>
  );
};
