import { useSupportMessages } from '#hooks';

import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import Table from './containers/Table';

export default () => {
  const [data, { loading, generalLoading }] = useSupportMessages();

  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Поддержка',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Table {...{ data, loading }} />
    </>
  );
};
