import { Form as Formik } from 'formik';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Container = styled(Formik)`
  background-color: ${colors.black._02};
  padding: ${ptr(24)};
  border-radius: ${ptr(4)};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${ptr(32)};
  border-top: ${ptr(1)} solid ${colors.black._05};
  margin: ${ptr(-24)};
  margin-top: ${ptr(24)};
  padding: ${ptr(24)};

  .custom-button {
    min-width: ${ptr(220)};
  }
`;

export const RightSideActions = styled.div`
  display: flex;

  .custom-button {
    white-space: nowrap;

    :not(:first-child) {
      margin-left: ${ptr(16)};
    }
  }
`;

export const LeftSideActions = styled.div`
  display: flex;

  .custom-button {
    white-space: nowrap;

    :not(:first-child) {
      margin-left: ${ptr(16)};
    }
  }
`;
