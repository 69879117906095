import apiService from '#services/api';
import debug from '#services/debug.service';
import { GetUsersDto } from '../user/dto/get-users.dto';
import { UsersResponse } from '../user/responses/user.response';

export class UsersService {
  private API_ROUTES = {
    users: 'user/crm',
  };

  public async getUsers({
    status,
    search = '',
    role,
    ...searchParams
  }: GetUsersDto) {
    try {
      const request = await apiService.request.get(`${this.API_ROUTES.users}`, {
        headers: {
          'content-type': 'application/json',
        },
        searchParams: {
          search,
          ...(() => {
            if (role) {
              return { ...{ role: role.join(',') } };
            }
            return {};
          })(),
          ...searchParams,
          ...(() => {
            if (status) {
              return { ...{ status } };
            }

            return {};
          })(),
        },
      });

      const result: UsersResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ UsersService ~ getUsers ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UsersService ~ getUsers ~ err:', err);

      throw err;
    }
  }
}
