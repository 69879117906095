export enum OrderPOSStatusEnum {
  /**
   * Статус неизвестен
  */
  UNKNOWN = 'UNKNOWN',

  /**
   * В обработке
   */
  NEW = 'NEW',

  /**
   * Приняли
   */
  ACCEPTED_BY_RESTAURANT = 'ACCEPTED_BY_RESTAURANT',

  /**
   * Ожидает очереди
   */
  POSTPONED = 'POSTPONED',

  /**
   * Готовится
   */
  COOKING = 'COOKING',

  /**
   * Заказ готов
   */
  READY = 'READY',

  /**
   * Курьер уже в пути
   */
  TAKEN_BY_COURIER = 'TAKEN_BY_COURIER',

  /**
   * Получен
   */
  DELIVERED = 'DELIVERED',

  /**
   * Отменен
   */
  CANCELLED = 'CANCELLED',

  /**
   * TODO: update string to ENUM
   */
}
