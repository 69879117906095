import { OptionProps } from '#ui-kit.types';

export const findSelectedOption = (
  value: string | null,
  options: OptionProps<string>[] = [],
): OptionProps<string> | null => {
  const optionsObject = options.reduce(
    (acc, current) => ({
      ...acc,
      [current.value]: {
        ...current,
      },
    }),
    {},
  ) as Record<string, OptionProps<string>>;

  if (!value) {
    return null;
  }

  return optionsObject[value];
};
