import { components } from 'react-select';
import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

import { OptionProps } from './types/Option.types';

export const StyledLabel = styled(Typography)`
  margin-top: ${ptr(4)};
`;

const IndicatorOption: React.FC<OptionProps> = ({ ...props }) => (
  <components.Option {...props}>
    <StyledLabel>{props.label}</StyledLabel>
  </components.Option>
);

export default IndicatorOption;
