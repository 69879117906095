import { Button, Typography, toastify } from '#ui-kit';
import { ButtonVariant } from '#ui-kit.types';
import { useRef, useState } from 'react';
import * as Styled from './styles';
import { FilesType } from '../PromotionView/Index';

type UploadPromoPhotoViewProps = {
  id: number | null;
  name: string;
  onRemovePhoto: () => void;
  type: keyof FilesType;
  setFiles: React.Dispatch<React.SetStateAction<FilesType>>;
};

const UploadPromoPhotoView: React.FC<UploadPromoPhotoViewProps> = ({
  id,
  name,
  type,
  setFiles,
  onRemovePhoto = () => {},
}) => {
  const fileInputStyle = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const noPhotoSrc = '/assets/no-photo.png';
  const imageSrc = id
    ? `${process.env.REACT_APP_API_MOBILE_URL}/files/${id}`
    : noPhotoSrc;

  const [fileSrc, setFileSrc] = useState(imageSrc);
  const [isRemoved, setIsRemoved] = useState(false);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsRemoved(false);
    const fileObj = event?.target?.files && event?.target?.files[0];
    if (!fileObj) {
      return;
    }
    setFiles((prevState) => ({
      ...prevState,
      [type]: fileObj,
    }));

    const filename = fileObj.name;
    const extension = filename.slice(filename.lastIndexOf('.')).toLowerCase();

    if (extension === '.png') {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setFileSrc(reader.result.toString());
        }
      };
      reader.readAsDataURL(fileObj);
    } else {
      toastify.error('Расширение изображения: .png');
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const removeImageHandler = async () => {
    setFileSrc(noPhotoSrc);
    setIsRemoved(true);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onRemovePhoto();
    if (imageSrc !== fileSrc) {
      setFiles((prevState) => ({
        ...prevState,
        [type]: null,
      }));
    }
  };
  return (
    <>
      <Styled.PromoItemContainer>
        <Typography>{name}</Typography>
        <Styled.PromoPhotoContainer>
          <input
            style={{ position: 'absolute', ...fileInputStyle }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
          <Styled.PromoPhoto src={isRemoved ? noPhotoSrc : fileSrc} />
        </Styled.PromoPhotoContainer>
        <Styled.PromoButtonContainer
          style={{
            justifyContent: fileSrc === noPhotoSrc ? 'center' : 'space-between',
          }}
        >
          <Styled.PromoButton>
            <Button
              label={fileSrc !== noPhotoSrc ? 'Изменить' : 'Добавить'}
              onClick={handleButtonClick}
              className="button"
            />
          </Styled.PromoButton>
          {fileSrc !== noPhotoSrc && (
            <Styled.PromoButton>
              <Button
                label="Удалить"
                onClick={removeImageHandler}
                variant={ButtonVariant.Outline}
                className="button"
              />
            </Styled.PromoButton>
          )}
        </Styled.PromoButtonContainer>
      </Styled.PromoItemContainer>
    </>
  );
};

export default UploadPromoPhotoView;
