import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .spinner {
    margin: 0 !important;
  }
`;

export const LoadingLabel = styled(Typography)`
  margin-top: ${ptr(8)};
`;
