import { Form as Formik } from 'formik';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Container = styled(Formik)`
  background-color: ${colors.black._02};
  padding: ${ptr(24)};
  border-radius: ${ptr(4)};
`;
