import React from 'react';

import { Provider } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import Routing from '#routing';

import {
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';

import { store } from '#store.config';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const StyledContainer = styled.main`
  .Toastify {
    &__toast {
      padding: ${ptr(16)};
      min-height: unset;
      border-radius: ${ptr(4)};
      background-color: ${colors.black._80};

      > .Toastify__toast-body {
        padding: 0;
        font-weight: ${TypographyWeight.Medium};
        font-size: ${ptr(TypographySize.xs)};
        line-height: 130%;
        color: ${colors.secondary._100};
        font-family: ${TypographyFamily.FactorA};
      }
    }

    &__toast--error {
      background-color: ${colors.error._100};

      > .Toastify__toast-body {
        color: ${colors.black._00};
      }
    }

    &__toast--success {
      background-color: ${colors.primary._100};

      > .Toastify__toast-body {
        color: ${colors.black._00};
      }
    }

    &__toast-container {
      width: ${ptr(343)};
      left: ${ptr(16)};
      bottom: 0;
      padding: 0;
    }

    &__close-button,
    &__toast-icon {
      display: none;
    }
  }
`;

const App: React.FC = () => (
  <StyledContainer>
    <Provider store={store}>
      <Routing />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        limit={2}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Zoom}
      />
    </Provider>
  </StyledContainer>
);

export default App;
