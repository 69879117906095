import { Form as Formik } from 'formik';
import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  width: ${ptr(420)};
  padding: ${ptr(32)};
  box-sizing: border-box;
  margin: ${ptr(32)} 0;
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: ${ptr(38)};
`;

export const Form = styled(Formik)`
  margin-top: ${ptr(24)};

  .input-container:not(:last-child) {
    margin-bottom: ${ptr(20)};
  }

  .auth__login-button {
    margin-top: ${ptr(64)};
  }
`;

export const Title = styled(Typography)`
  position: relative;
  text-align: center;
`;

export const Underline = styled.img`
  width: ${ptr(32)};
  position: absolute;
  top: ${ptr(-20)};
`;
