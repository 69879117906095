import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';

import { StatusBadgeVariant } from './types/StatusBadge.types';

const generateColor = (
  variant: StatusBadgeVariant,
): { backgroundColor: string; color: string } => {
  const result = {
    color: colors.black._00,
    backgroundColor: colors.black._80,
  };

  if (variant === StatusBadgeVariant.Warning) {
    result.backgroundColor = colors.warning._100;
  }

  if (variant === StatusBadgeVariant.Disabled) {
    result.backgroundColor = colors.black._80;
  }

  if (variant === StatusBadgeVariant.Blocked) {
    result.backgroundColor = colors.error._100;
  }

  if (variant === StatusBadgeVariant.Active) {
    result.backgroundColor = colors.primary._100;
  }

  if (variant === StatusBadgeVariant.Processing) {
    result.backgroundColor = colors.blue._10;
  }

  return result;
};

export const Container = styled.div<{ variant: StatusBadgeVariant }>(
  ({ variant }) => {
    const { backgroundColor } = generateColor(variant);
    return {
      padding: `${ptr(8)} ${ptr(12)}`,
      borderRadius: ptr(4),
      maxWidth: 'fit-content',
      backgroundColor,
      textAlign: 'center',
    };
  },
);

export const Label = styled(Typography)<{ variant: StatusBadgeVariant }>(
  ({ variant }) => {
    const { color } = generateColor(variant);
    return {
      color,
      marginTop: ptr(2),
      letterSpacing: ptr(0.3),
    };
  },
);
