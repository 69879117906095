import * as Yup from 'yup';

export const PHONE_NUMBER_REGULAR_EXPRESSION =
  /^(\+7)\s[\()]\(?\d{3}\)?[\)]\s\d{3}[\-]?\d{2}[\-]?\d{2}$/;

export const phoneNumberValidationSchema = Yup.string().test(
  'phone',
  'Введите корректный номер телефона',
  (value) => (value ? PHONE_NUMBER_REGULAR_EXPRESSION.test(value) : true),
);

const extractDigits = (str: string): string => {
  const pattern = /[^\d+]/g;
  return str.replace(pattern, '');
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // if (cleanedPhoneNumber.startsWith('375')) {
  //   return `+375 (${cleanedPhoneNumber.slice(3, 5)}) ${cleanedPhoneNumber.slice(
  //     5,
  //     8,
  //   )}-${cleanedPhoneNumber.slice(8, 10)}-${cleanedPhoneNumber.slice(10)}`;
  // }

  if (cleanedPhoneNumber.startsWith('7')) {
    return `+7 (${cleanedPhoneNumber.slice(1, 4)}) ${cleanedPhoneNumber.slice(
      4,
      7,
    )}-${cleanedPhoneNumber.slice(7, 9)}-${cleanedPhoneNumber.slice(9)}`;
  }

  return phoneNumber;
};

export default extractDigits;
