import { StatusBadgeVariant } from '#components.types';
import { OrderDeliveryTypeEnum } from '#services/orders/enums/order-delivery-type.enum';
import { OrderInternalStatusEnum } from '#services/orders/enums/order-internal-status.enum';
import { OrderPaymentStatusEnum } from '#services/orders/enums/order-payment-status.enum';
import { OrderPaymentEnum } from '#services/orders/enums/order-payment.enum';
import { OrderPOSStatusEnum } from '#services/orders/enums/order-pos-status.enum';

export const OrderPOSStatusBadgeMap = {
  [OrderPOSStatusEnum.NEW]: {
    label: 'В обработке',
    variant: StatusBadgeVariant.Warning,
  },
  [OrderPOSStatusEnum.UNKNOWN]: {
    label: 'Неизвестен',
    variant: StatusBadgeVariant.Blocked,
  },
  [OrderPOSStatusEnum.ACCEPTED_BY_RESTAURANT]: {
    label: 'Приняли',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPOSStatusEnum.POSTPONED]: {
    label: 'Ожидает очереди',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPOSStatusEnum.COOKING]: {
    label: 'Готовится',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPOSStatusEnum.READY]: {
    label: 'Готов',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPOSStatusEnum.TAKEN_BY_COURIER]: {
    label: 'Передан курьеру',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPOSStatusEnum.DELIVERED]: {
    label: 'Доставлен',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPOSStatusEnum.CANCELLED]: {
    label: 'Отменен',
    variant: StatusBadgeVariant.Blocked,
  },
};

export const OrderInternalStatusBadgeMap = {
  [OrderInternalStatusEnum.NEW]: {
    label: 'Создаётся',
    variant: StatusBadgeVariant.Disabled,
  },
  [OrderInternalStatusEnum.COMPLETED]: {
    label: 'Завершен',
    variant: StatusBadgeVariant.Active,
  },
  [OrderInternalStatusEnum.IN_PROGRESS]: {
    label: 'В процессе',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderInternalStatusEnum.CANCELED]: {
    label: 'Оменен',
    variant: StatusBadgeVariant.Blocked,
  },
  [OrderInternalStatusEnum.FAILED]: {
    label: 'Завершен с ошибкой',
    variant: StatusBadgeVariant.Blocked,
  },
};

export const OrderPaymentTypeBadgeMap = {
  [OrderPaymentEnum.BANK_CARD_IN_APP]: {
    label: 'Банковской картой в приложении',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPaymentEnum.BANK_CARD_IN_STORE]: {
    label: 'Банковской картой при получении',
    variant: StatusBadgeVariant.Warning,
  },
  [OrderPaymentEnum.CASH]: {
    label: 'Наличными',
    variant: StatusBadgeVariant.Processing,
  },
};

export const OrderDeliveryTypeBadgeMap = {
  [OrderDeliveryTypeEnum.delivery]: {
    label: 'Доставка',
    variant: StatusBadgeVariant.Active,
  },
  [OrderDeliveryTypeEnum.pickup]: {
    label: 'Самовывоз',
    variant: StatusBadgeVariant.Processing,
  },
};

export const OrderTransactionStatusBadgeMap = {
  [OrderPaymentStatusEnum.NEW]: {
    label: 'В обработке',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPaymentStatusEnum.FORM_SHOWED]: {
    label: 'Заполнение формы 3DS',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPaymentStatusEnum['3DS_CHECKING']]: {
    label: 'Проверка 3DS',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPaymentStatusEnum['3DS_CHECKED']]: {
    label: '3DS пройден',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPaymentStatusEnum.AUTHORIZING]: {
    label: 'Прохождение авторизации',
    variant: StatusBadgeVariant.Processing,
  },
  [OrderPaymentStatusEnum.AUTHORIZED]: {
    label: 'Средства заморожены',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPaymentStatusEnum.CONFIRMED]: {
    label: 'Средства списаны',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPaymentStatusEnum.COMPLETED]: {
    label: 'Средства списаны',
    variant: StatusBadgeVariant.Active,
  },
  [OrderPaymentStatusEnum.REJECTED]: {
    label: 'Платёж отклонен',
    variant: StatusBadgeVariant.Blocked,
  },
  [OrderPaymentStatusEnum.CANCELED]: {
    label: 'Платёж отменен',
    variant: StatusBadgeVariant.Blocked,
  },
  [OrderPaymentStatusEnum.PARTIAL_REVERSED]: {
    label: 'Средства частично разморожены',
    variant: StatusBadgeVariant.Warning,
  },
  [OrderPaymentStatusEnum.REVERSED]: {
    label: 'Средства полностью разморожены',
    variant: StatusBadgeVariant.Warning,
  },
  [OrderPaymentStatusEnum.PARTIAL_REFUNDED]: {
    label: 'Средства частично возвращены',
    variant: StatusBadgeVariant.Warning,
  },
  [OrderPaymentStatusEnum.REFUNDED]: {
    label: 'Средства полностью возвращены',
    variant: StatusBadgeVariant.Warning,
  },
};
