import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';
import { TypographyFamily, TypographySize } from '#ui-kit.types';
import styled from 'styled-components';

export const RestaurantItem = styled.div`
  background: ${colors.black._02};
  padding: ${ptr(10)};
  padding-left: ${ptr(20)};
  transition: ${transition.average};

  .filter_item_text {
    font-size: ${TypographySize.m};
    font-family: ${TypographyFamily.FactorA};
    text-align: left;
  }

  :hover {
    cursor: pointer;
  }
`;

export const RestaurantsContainer = styled.div`
  max-height: ${ptr(300)};
  width: 100%;
`;

export const FlexContainer = styled.div`
  width: 96%;
  background: ${colors.black._02};
  display: flex;
  padding: ${ptr(16)};

  :first-child {
    border-bottom: ${ptr(1)} solid ${colors.black._05};
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // border: ${ptr(1)} solid ${colors.black._05};
  // padding: ${ptr(16)};

  :first-child {
    flex: 1;
  }

  :last-child {
    flex: 2;
  }
`;

export const ContainerItem = styled.div`
  display: flex;

  :first-child {
    flex: 1;
  }

  :last-child {
    flex: 2;
    flex-direction: column;
    padding-left: ${ptr(48)};
  }

  .price {
    margin-right: ${ptr(16)};
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  gap: ${ptr(32)};
  flex-wrap: wrap;
`;

export const PriceContainer = styled.div`
  margin-bottom: ${ptr(32)};
  display: flex;
  justify-content: center;
`;
