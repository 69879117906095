import { ptr } from '#styles.utils';

import { colors } from '#styles.variables';
import { Typography } from '#ui-kit';
import {
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import {
  OrderResponse,
} from '#services/orders/responses/order.response';
import * as Styled from './style';
import { formatPrice } from 'utils/formatPrice';

type ViewProps = {
  data: OrderResponse['prices'],
  options: {
    mode: 'VIEW',
  } | {
    mode: 'REFUND',
    priceToRefund: number;
  };
};

const receiptLocalization = {
  totalPrice: 'Итого',
  basePrice: 'Подытог',
  discountedPrice: 'Скидка',
  deliveryPrice: 'Доставка',
  refundedPrice: 'Возвращено',
  toRefund: 'К возврату',
  ruble: 'руб.',
};

const OrderPrices: React.FC<ViewProps> = ({
  data,
  options,
}) => (
  options.mode === 'REFUND'
    ? <Styled.PricesContainer>
      <Styled.PriceLine style={{ marginTop: ptr(10) }}>
        <Typography
          size={TypographySize.xl}
          tag={TypographyTag.span}
          style={{
            textAlign: 'left',
          }}
        >
          {receiptLocalization.toRefund + ': '}
        </Typography>
        <Typography
          size={TypographySize.xl}
          tag={TypographyTag.span}
          style={{
            textAlign: 'right',
          }}
        >
          {notAvailableValue(formatPrice(options.priceToRefund)) + ' ' + receiptLocalization.ruble}
        </Typography>
      </Styled.PriceLine>
    </Styled.PricesContainer>
    : <Styled.PricesContainer>
        <Styled.PriceLine>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              textAlign: 'left',
            }}
          >
            {receiptLocalization.basePrice + ': '}
          </Typography>
          <Typography
            size={TypographySize.l}
            tag={TypographyTag.span}
            style={{
              textAlign: 'right',
            }}
          >
            {formatPrice(data?.basePrice) + ' ' + receiptLocalization.ruble}
          </Typography>
        </Styled.PriceLine>
        { data?.basePrice !== data?.discountedPrice && <Styled.PriceLine>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              textAlign: 'right',
            }}
          >
            {receiptLocalization.discountedPrice + ': '}
          </Typography>
          <Typography
            size={TypographySize.l}
            tag={TypographyTag.span}
            style={{
              textAlign: 'right',
            }}
          >
            {formatPrice(data?.basePrice - data?.discountedPrice) + ' ' + receiptLocalization.ruble}
          </Typography>
        </Styled.PriceLine> }
        { data?.deliveryPrice > 0 && <Styled.PriceLine>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              textAlign: 'left',
            }}
          >
            {receiptLocalization.deliveryPrice + ': '}
          </Typography>
          <Typography
            size={TypographySize.l}
            tag={TypographyTag.span}
            style={{
              textAlign: 'right',
            }}
          >
            {formatPrice(data?.deliveryPrice) + ' ' + receiptLocalization.ruble}
          </Typography>
        </Styled.PriceLine> }
        <Styled.PriceLine style={{ marginTop: ptr(10) }}>
          <Typography
            size={TypographySize.xl}
            tag={TypographyTag.span}
            style={{
              textAlign: 'left',
            }}
          >
            {receiptLocalization.totalPrice + ': '}
          </Typography>
          <Typography
            size={TypographySize.xl}
            tag={TypographyTag.span}
            style={{
              textAlign: 'right',
            }}
          >
            {formatPrice(data?.deliveryPrice + data?.discountedPrice) + ' ' + receiptLocalization.ruble}
          </Typography>
        </Styled.PriceLine>
        <Styled.PriceLine>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              textAlign: 'left',
              color: colors.black._80,
            }}
          >
            {receiptLocalization.refundedPrice + ': '}
          </Typography>
          <Typography
            size={TypographySize.l}
            tag={TypographyTag.span}
            style={{
              textAlign: 'right',
              color: colors.black._80,
            }}
          >
            {notAvailableValue(formatPrice(data?.refundedPrice)) + ' ' + receiptLocalization.ruble}
          </Typography>
        </Styled.PriceLine>
      </Styled.PricesContainer>
);

export default OrderPrices;
