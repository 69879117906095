export enum LogotypeVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum LogotypeSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type LogotypeProps = Readonly<
  Partial<{
    className: string;
    isLink: boolean;
    variant: LogotypeVariant;
    size: LogotypeSize;
  }>
>;
