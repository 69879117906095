import { Link } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { hexToRgbA, ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographyTag, TypographyWeight } from '#ui-kit.types';

const componentStyles: CSSObject = {
  padding: `${ptr(8)} ${ptr(16)}`,
  borderRadius: ptr(4),
  transition: transition.average,
};

export const Container = styled.div`
  padding: ${ptr(4)};
  border-radius: ${ptr(4)};
  display: flex;
  background-color: ${colors.black._02};
  max-width: fit-content;
`;

export const SwitchButton = styled.button<{ isActive: boolean }>(
  ({ isActive }) => ({
    ...componentStyles,

    ...(() => {
      if (isActive) {
        return {
          background: colors.primary._100,

          ':hover, :focus': {
            boxShadow: `0 0 ${ptr(4)} ${hexToRgbA(colors.black._100, 40)}`,
          },
        };
      }

      return {};
    })(),
  }),
);

export const SwitchLink = styled(Link)<{ isActive: boolean }>(
  ({ isActive }) => ({
    ...componentStyles,
    textDecoration: 'none',

    ...(() => {
      if (isActive) {
        return {
          background: colors.primary._100,

          ':hover, :focus': {
            boxShadow: `0 0 ${ptr(4)} ${hexToRgbA(colors.black._100, 40)}`,
          },
        };
      }

      return {};
    })(),
  }),
);

export const Label = styled(Typography).attrs({
  tag: TypographyTag.p,
  weight: TypographyWeight.Light,
})`
  margin-top: ${ptr(4)};
  transition: ${transition.average};
`;
