import { useState } from 'react';

import { useUsers } from '#hooks';

import { IndicatorOption } from '#ui-kit';

import { Controllers, Head, LoadingContent } from '#components';
import { SwitcherType } from '#components.types';

import { findSelectedOption, statusOptions as options } from '#utils';

import { UserRole, UserStatus } from '#services/user/responses/user.response';

import Table from './containers/Table';

export default () => {
  const [searchValue, setSearchValue] = useState('');
  const [filterSelectedOption, setFilterSelectedOption] =
    useState<UserStatus | null>(null);

  const [data, { loading, generalLoading, preLoading }] = useUsers({
    role: [UserRole.USER],
    search: searchValue,
    status: filterSelectedOption,
  });

  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Пользователи',
          switcher: {
            type: SwitcherType.Linked,
            activeId: UserRole.USER,
            data: [
              {
                id: UserRole.USER,
                to: '/users',
                label: 'Пользователи',
              },
              {
                id: UserRole.ADMIN,
                to: '/users/admins',
                label: 'Администраторы',
              },
            ],
          },
        }}
      />
      <Controllers
        {...{
          searchInputProps: {
            value: searchValue,
            onChange: setSearchValue,
            placeholder: 'Поиск по номеру телефона или электронной почте',
          },
          filterProps: {
            value: findSelectedOption(filterSelectedOption, options),
            onChange: (output) => {
              if (output?.value)
                setFilterSelectedOption(output.value as UserStatus);
              else setFilterSelectedOption(null);
            },
            options,
            clearable: true,
            placeholder: 'Фильтр по статусу',
            components: { Option: IndicatorOption },
          },
        }}
      />
      <Table {...{ data, loading, preLoading }} />
    </>
  );
};
