import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Typography } from '#ui-kit';

import { MenuItemProps, MenuItemType } from './types/Header.types';

export const Container = styled.div`
  border-bottom: ${ptr(1)} solid ${colors.black._02};
  padding: ${ptr(16)} ${ptr(32)};
  position: sticky;
  top: 0;
  z-index: 15;
  display: flex;
  justify-content: space-between;
  background: ${colors.black._00};
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const User = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;

  :hover {
    .header__menu {
      filter: blur(0);
      transform: scale(1);
    }
  }
`;

export const Username = styled(Typography)`
  user-select: none;
`;

export const Avatar = styled.img`
  background-color: ${colors.black._02};
  width: ${ptr(54)};
  height: ${ptr(54)};
  flex-grow: 1;
  border-radius: ${ptr(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  object-position: center;
`;

export const Paragraph = styled(Typography)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: ${ptr(16)};
  padding-right: ${ptr(24)};
  border-right: ${ptr(1)} solid ${colors.black._02};
`;

export const UserRole = styled(Typography)`
  margin-top: ${ptr(4)};
  user-select: none;
`;

export const MenuItem = styled.li<Pick<MenuItemProps, 'type'>>`
  transition: ${transition.average};

  :not(:last-child) {
    border-bottom: ${ptr(1)} solid ${colors.black._02};
  }

  ${({ type }) => {
    if (type === MenuItemType.Secondary) {
      return css`
        :hover {
          background: ${colors.error._10};

          .menu__name {
            color: ${colors.error._100};
          }

          svg path {
            fill: ${colors.error._100};
          }
        }
      `;
    }

    return css`
      :hover {
        background: ${colors.black._02};

        & + #menu-triangle {
          ::after {
            border-bottom-color: ${colors.black._02};
          }
        }
      }
    `;
  }}
`;

export const Menu = styled.nav`
  background-color: ${colors.black._00};
  border-radius: ${ptr(4)};
  border: ${ptr(1)} solid ${colors.black._02};
`;

export const MenuList = styled.ul``;

export const MenuContainer = styled.div`
  position: absolute;
  transform: scale(0);
  top: ${ptr(54)};
  width: 100%;
  transition: ${transition.average};
  filter: blur(${ptr(4)});
`;

export const HelperContainer = styled.div`
  height: ${ptr(32)};
  cursor: default;
`;

export const MenuItemName = styled(Typography)`
  transition: ${transition.average};
  margin-left: ${ptr(10)};
  margin-top: ${ptr(4)};
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${ptr(16)} ${ptr(24)};
  width: 100%;

  svg path {
    transition: ${transition.average};
  }
`;

export const Triangle = styled.figure`
  width: 0;
  height: 0;
  border-left: ${ptr(12)} solid transparent;
  border-right: ${ptr(12)} solid transparent;
  border-bottom: ${ptr(12)} solid ${colors.black._02};
  position: absolute;
  left: ${ptr(16)};
  top: ${ptr(20)};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: default;

  &::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: ${ptr(-13)};
    border-left: ${ptr(11)} solid transparent;
    border-right: ${ptr(11)} solid transparent;
    border-bottom: ${ptr(11)} solid ${colors.black._00};
    transition: ${transition.average};
  }
`;

export const LogoutButton = styled.button`
  width: ${ptr(54)};
  height: ${ptr(54)};
  flex-grow: 1;
  border-radius: ${ptr(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: ${ptr(24)};
  transition: ${transition.average};
`;

export const LogoutLabel = styled(Typography)`
  margin-top: ${ptr(4)};
`;
