const ptr = (points: number): string => `${points / 16}rem`;

type BorderStyleType =
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
  | 'none'
  | 'hidden';

type BorderSideType = 'top' | 'bottom' | 'left' | 'right';

export type BorderType = {
  readonly width: number;
  readonly style: BorderStyleType;
  readonly color: string;
  readonly sides?: Array<BorderSideType>;
};

const hexToRgbA = (hex = '#FFFFFF', alpha = 1): string => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${
      alpha / 100
    })`;
  }
  throw new Error('Bad Hex');
};

function borderParams(border: BorderType) {
  const bw = border.sides
    ? border.sides.reduce(
        (result, side: BorderSideType) => ({
          ...result,
          [`border-${side}-width`]: ptr(border.width),
        }),
        {},
      )
    : { borderwidth: ptr(border.width) };

  return {
    ...bw,
    'border-style': border.style,
    'border-color': border.color,
  };
}

export { ptr, hexToRgbA, borderParams };
