import React from 'react';

import { colors } from '#styles.variables';

import { IconNames } from '#ui-kit.types';

import IconsNames from './types/Icons.names';
import { IconProps } from './types/Icons.types';

const IconComponent: React.FC<IconProps> = ({
  name,
  width = 24,
  height = 24,
  fill = colors.secondary._100,
  stroke = colors.secondary._100,
}) => {
  switch (name) {
    case IconsNames.cross:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 7L7 17M7 7L17 17"
            stroke={stroke}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case IconsNames.eye:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 12C3 12 6.27273 6 12 6C17.7273 6 21 12 21 12C21 12 17.7273 18 12 18C6.27273 18 3 12 3 12Z"
            stroke={stroke}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 14.25C13.3556 14.25 14.4545 13.2426 14.4545 12C14.4545 10.7574 13.3556 9.75 12 9.75C10.6444 9.75 9.54545 10.7574 9.54545 12C9.54545 13.2426 10.6444 14.25 12 14.25Z"
            stroke={stroke}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case IconsNames['closed-eye']:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.2818 5.65091C10.845 5.51908 11.4216 5.45319 12 5.45455C17.7273 5.45455 21 12 21 12C20.5033 12.9291 19.911 13.8039 19.2327 14.61M13.7345 13.7345C13.5098 13.9757 13.2388 14.1691 12.9378 14.3033C12.6367 14.4374 12.3116 14.5096 11.9821 14.5154C11.6525 14.5212 11.3251 14.4606 11.0195 14.3371C10.7139 14.2137 10.4362 14.0299 10.2031 13.7969C9.97007 13.5638 9.78632 13.2861 9.66287 12.9805C9.53942 12.6749 9.47879 12.3475 9.48461 12.0179C9.49042 11.6884 9.56256 11.3633 9.69672 11.0622C9.83087 10.7612 10.0243 10.4902 10.2655 10.2655M3 3L21 21M16.86 16.86C15.4614 17.9261 13.7584 18.5167 12 18.5455C6.27273 18.5455 3 12 3 12C4.01773 10.1034 5.42929 8.44632 7.14 7.14L16.86 16.86Z"
            stroke={stroke}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case IconsNames.user:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.997 15.1747C7.684 15.1747 4 15.8547 4 18.5747C4 21.2957 7.661 21.9997 11.997 21.9997C16.31 21.9997 19.994 21.3207 19.994 18.5997C19.994 15.8787 16.334 15.1747 11.997 15.1747Z"
            fill={fill}
          />
          <path
            opacity="0.4"
            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.info:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1226 7.84586 17.2658 5.78065 15.7426 4.25745C14.2193 2.73425 12.1541 1.87744 10 1.875ZM9.8438 5.62503C10.0292 5.62503 10.2105 5.68001 10.3646 5.78303C10.5188 5.88604 10.639 6.03246 10.7099 6.20376C10.7809 6.37507 10.7995 6.56357 10.7633 6.74543C10.7271 6.92728 10.6378 7.09433 10.5067 7.22544C10.3756 7.35655 10.2085 7.44584 10.0267 7.48201C9.84484 7.51819 9.65634 7.49962 9.48503 7.42867C9.31372 7.35771 9.16731 7.23755 9.06429 7.08338C8.96128 6.9292 8.9063 6.74795 8.9063 6.56253C8.9063 6.31389 9.00507 6.07543 9.18088 5.89962C9.3567 5.7238 9.59516 5.62503 9.8438 5.62503ZM10.625 14.375H10C9.83425 14.375 9.67528 14.3091 9.55807 14.1919C9.44087 14.0747 9.37502 13.9158 9.37501 13.75V10C9.20925 10 9.05028 9.93415 8.93307 9.81694C8.81586 9.69973 8.75001 9.54076 8.75001 9.375C8.75001 9.20924 8.81586 9.05027 8.93307 8.93306C9.05028 8.81585 9.20925 8.75 9.37501 8.75H10C10.1658 8.75001 10.3247 8.81586 10.4419 8.93307C10.5591 9.05028 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.logout:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
            fill={fill}
          />
          <path
            d="M21.7792 11.4548L18.9334 8.5458C18.6393 8.2458 18.166 8.2458 17.8728 8.5478C17.5807 8.8498 17.5816 9.3368 17.8748 9.6368L19.434 11.2298H17.9391H9.54875C9.13483 11.2298 8.79883 11.5748 8.79883 11.9998C8.79883 12.4258 9.13483 12.7698 9.54875 12.7698H19.434L17.8748 14.3628C17.5816 14.6628 17.5807 15.1498 17.8728 15.4518C18.0199 15.6028 18.2118 15.6788 18.4046 15.6788C18.5955 15.6788 18.7873 15.6028 18.9334 15.4538L21.7792 12.5458C21.9204 12.4008 22.0003 12.2048 22.0003 11.9998C22.0003 11.7958 21.9204 11.5998 21.7792 11.4548Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames['arrow-left']:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 9 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.53033 19.5303C8.7966 19.2641 8.8208 18.8474 8.60295 18.5538L8.53033 18.4697L2.061 12L8.53033 5.53033C8.7966 5.26406 8.8208 4.8474 8.60295 4.55379L8.53033 4.46967C8.26406 4.2034 7.8474 4.1792 7.55379 4.39705L7.46967 4.46967L0.46967 11.4697C0.203403 11.7359 0.179197 12.1526 0.397052 12.4462L0.46967 12.5303L7.46967 19.5303C7.76256 19.8232 8.23744 19.8232 8.53033 19.5303Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames['arrow-right']:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 9 24"
          fill="none"
        >
          <path
            d="M0.46967 19.5303C0.203403 19.2641 0.179197 18.8474 0.397052 18.5538L0.46967 18.4697L6.939 12L0.46967 5.53033C0.203403 5.26406 0.179197 4.8474 0.397052 4.55379L0.46967 4.46967C0.735936 4.2034 1.1526 4.1792 1.44621 4.39705L1.53033 4.46967L8.53033 11.4697C8.7966 11.7359 8.8208 12.1526 8.60295 12.4462L8.53033 12.5303L1.53033 19.5303C1.23744 19.8232 0.762563 19.8232 0.46967 19.5303Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.users:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.9508 14.5399C8.5008 14.5399 5.58984 15.1037 5.58984 17.2794C5.58984 19.4561 8.5196 20 11.9508 20C15.4008 20 18.3117 19.4362 18.3117 17.2605C18.3117 15.0839 15.382 14.5399 11.9508 14.5399Z"
            fill={fill}
          />
          <path
            opacity="0.4"
            d="M11.9476 12.467C14.2837 12.467 16.1569 10.5831 16.1569 8.23351C16.1569 5.88306 14.2837 4 11.9476 4C9.61146 4 7.73828 5.88306 7.73828 8.23351C7.73828 10.5831 9.61146 12.467 11.9476 12.467Z"
            fill={fill}
          />
          <path
            opacity="0.4"
            d="M21.0886 9.21929C21.693 6.84182 19.9209 4.7066 17.6645 4.7066C17.4192 4.7066 17.1846 4.73362 16.9554 4.77955C16.9249 4.78675 16.8909 4.80206 16.873 4.82908C16.8524 4.8633 16.8676 4.90923 16.89 4.93895C17.5678 5.89534 17.9573 7.05976 17.9573 8.30973C17.9573 9.50747 17.6001 10.6242 16.9733 11.5508C16.9088 11.6463 16.9661 11.7751 17.0798 11.7949C17.2374 11.8228 17.3986 11.8372 17.5634 11.8417C19.2064 11.8849 20.6811 10.8214 21.0886 9.21929Z"
            fill={fill}
          />
          <path
            d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
            fill={fill}
          />
          <path
            opacity="0.4"
            d="M7.04508 4.77979C6.81675 4.73296 6.58126 4.70685 6.33592 4.70685C4.0795 4.70685 2.3075 6.84207 2.91279 9.21953C3.31931 10.8216 4.79403 11.8852 6.4371 11.842C6.60185 11.8375 6.76392 11.8221 6.92061 11.7951C7.03433 11.7753 7.09164 11.6465 7.02717 11.5511C6.40039 10.6235 6.04312 9.50771 6.04312 8.30997C6.04312 7.0591 6.43352 5.89468 7.11134 4.93919C7.13283 4.90947 7.14894 4.86354 7.12745 4.82932C7.10954 4.80141 7.07642 4.787 7.04508 4.77979Z"
            fill={fill}
          />
          <path
            d="M3.32156 13.5126C2.21752 13.7297 1.49225 14.1718 1.19139 14.8166C0.936203 15.3453 0.936203 15.9585 1.19139 16.4872C1.65163 17.485 3.13531 17.8065 3.71195 17.8884C3.83104 17.9065 3.92595 17.8038 3.91342 17.6831C3.61883 14.9166 5.9621 13.6045 6.56918 13.3028C6.59425 13.2884 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2046 3.84358 13.3848 3.32156 13.5126Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.location:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z"
            fill={fill}
          />
          <ellipse opacity="0.4" cx="12" cy="21" rx="5" ry="1" fill={fill} />
        </svg>
      );
    case IconNames.notification:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
            opacity="0.4"
            fill={fill}
          />
          <path
            d="M14.0097 19.2284C13.5098 19.1216 10.4636 19.1216 9.96372 19.2284C9.53636 19.3271 9.07422 19.5567 9.07422 20.0602C9.09907 20.5406 9.38033 20.9647 9.76992 21.2336L9.76893 21.2346C10.2728 21.6273 10.8642 21.8771 11.4833 21.9668C11.8133 22.0121 12.1492 22.0101 12.4911 21.9668C13.1093 21.8771 13.7006 21.6273 14.2045 21.2346L14.2035 21.2336C14.5931 20.9647 14.8744 20.5406 14.8992 20.0602C14.8992 19.5567 14.4371 19.3271 14.0097 19.2284Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.chat:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9802 13.2901C11.2702 13.2801 10.7002 12.7101 10.7002 12.0001C10.7002 11.3001 11.2802 10.7201 11.9802 10.7301C12.6902 10.7301 13.2602 11.3001 13.2602 12.0101C13.2602 12.7101 12.6902 13.2901 11.9802 13.2901ZM7.36984 13.2901C6.66984 13.2901 6.08984 12.7101 6.08984 12.0101C6.08984 11.3001 6.65984 10.7301 7.36984 10.7301C8.07984 10.7301 8.64984 11.3001 8.64984 12.0101C8.64984 12.7101 8.07984 13.2801 7.36984 13.2901ZM15.31 12.0101C15.31 12.7101 15.88 13.2901 16.59 13.2901C17.3 13.2901 17.87 12.7101 17.87 12.0101C17.87 11.3001 17.3 10.7301 16.59 10.7301C15.88 10.7301 15.31 11.3001 15.31 12.0101Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.coupons:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M21.25 13.4764C20.429 13.4764 19.761 12.8145 19.761 12.001C19.761 11.1865 20.429 10.5246 21.25 10.5246C21.449 10.5246 21.64 10.4463 21.78 10.3076C21.921 10.1679 22 9.97864 22 9.78146L21.999 7.10415C21.999 4.84102 20.14 3 17.856 3H6.144C3.86 3 2.001 4.84102 2.001 7.10415L2 9.86766C2 10.0648 2.079 10.2541 2.22 10.3938C2.36 10.5325 2.551 10.6108 2.75 10.6108C3.599 10.6108 4.239 11.2083 4.239 12.001C4.239 12.8145 3.571 13.4764 2.75 13.4764C2.336 13.4764 2 13.8093 2 14.2195V16.8949C2 19.158 3.858 21 6.143 21H17.857C20.142 21 22 19.158 22 16.8949V14.2195C22 13.8093 21.664 13.4764 21.25 13.4764Z"
            fill={fill}
          />
          <path
            d="M15.4298 11.5887L14.2508 12.7367L14.5298 14.3597C14.5778 14.6407 14.4648 14.9177 14.2338 15.0837C14.0048 15.2517 13.7058 15.2727 13.4538 15.1387L11.9988 14.3737L10.5408 15.1397C10.4328 15.1967 10.3148 15.2267 10.1978 15.2267C10.0448 15.2267 9.89385 15.1787 9.76385 15.0847C9.53385 14.9177 9.42085 14.6407 9.46885 14.3597L9.74685 12.7367L8.56785 11.5887C8.36385 11.3907 8.29285 11.0997 8.38085 10.8287C8.46985 10.5587 8.69985 10.3667 8.98085 10.3267L10.6068 10.0897L11.3358 8.61268C11.4628 8.35868 11.7168 8.20068 11.9988 8.20068H12.0008C12.2838 8.20168 12.5378 8.35968 12.6628 8.61368L13.3918 10.0897L15.0208 10.3277C15.2988 10.3667 15.5288 10.5587 15.6168 10.8287C15.7058 11.0997 15.6348 11.3907 15.4298 11.5887Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.orders:
      return (
        <svg
          aria-hidden="true"
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          fill={fill}
          viewBox="0 0 24 24"
        >
          <path d="M15 14H7.78l-.5-2H16a1 1 0 0 0 .962-.726l.473-1.655A2.968 2.968 0 0 1 16 10a3 3 0 0 1-3-3 3 3 0 0 1-3-3 2.97 2.97 0 0 1 .184-1H4.77L4.175.745A1 1 0 0 0 3.208 0H1a1 1 0 0 0 0 2h1.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 10 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3Zm-8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm8 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
          <path d="M19 3h-2V1a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V5h2a1 1 0 1 0 0-2Z" />
        </svg>
      );
    case IconNames.document:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.07996 6.65002V6.66002C5.64896 6.66002 5.29996 7.01002 5.29996 7.44002C5.29996 7.87002 5.64896 8.22002 6.07996 8.22002H9.06896C9.49996 8.22002 9.84996 7.87002 9.84996 7.42902C9.84996 7.00002 9.49996 6.65002 9.06896 6.65002H6.07996ZM13.92 12.74H6.07996C5.64896 12.74 5.29996 12.39 5.29996 11.96C5.29996 11.53 5.64896 11.179 6.07996 11.179H13.92C14.35 11.179 14.7 11.53 14.7 11.96C14.7 12.39 14.35 12.74 13.92 12.74ZM13.92 17.31H6.07996C5.77996 17.35 5.48996 17.2 5.32996 16.95C5.16996 16.69 5.16996 16.36 5.32996 16.11C5.48996 15.85 5.77996 15.71 6.07996 15.74H13.92C14.319 15.78 14.62 16.12 14.62 16.53C14.62 16.929 14.319 17.27 13.92 17.31Z"
            fill={fill}
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.32723 2H13.867C16.646 2 18.1934 3.78 18.1934 6.83L18.1359 10H18.3662H20.9995C21.5518 10 21.9995 10.4477 21.9995 11V19.4333C21.9995 20.8509 20.8504 22 19.4329 22C19.4283 22 19.4237 22 19.4191 22C19.4183 22 19.4175 22 19.4166 22H6.32723C3.59235 22 2 20.26 2 17.16V6.83C2 3.78 3.59235 2 6.32723 2ZM18.3662 11.5H20.4995V19.4333C20.4995 20.0224 20.022 20.5 19.4329 20.5C18.8438 20.5 18.3662 20.0224 18.3662 19.4333V11.5Z"
            fill={fill}
          />
          <path
            d="M18 11H20C20.5523 11 21 11.4477 21 12V19.5C21 20.3284 20.3284 21 19.5 21C18.6716 21 18 20.3284 18 19.5V11Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames['arrow-down']:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 16 24"
          fill="none"
        >
          <path
            d="M0.46967 7.96967C0.735936 7.7034 1.1526 7.6792 1.44621 7.89705L1.53033 7.96967L8 14.439L14.4697 7.96967C14.7359 7.7034 15.1526 7.6792 15.4462 7.89705L15.5303 7.96967C15.7966 8.23594 15.8208 8.6526 15.6029 8.94621L15.5303 9.03033L8.53033 16.0303C8.26406 16.2966 7.8474 16.3208 7.55379 16.1029L7.46967 16.0303L0.46967 9.03033C0.176777 8.73744 0.176777 8.26256 0.46967 7.96967Z"
            fill={fill}
          />
        </svg>
      );
    case IconNames.magnifier:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle
            cx="11.7669"
            cy="11.7666"
            r="8.98856"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0186 18.4852L21.5426 22.0001"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case IconNames.onbording:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="18"
            height="19"
            transform="translate(0.72998)"
            fill="#F5F9EC"
          />
          <path
            d="M12.6154 2.3938H6.84496C6.46249 2.39426 6.09581 2.55484 5.82536 2.84031C5.55491 3.12579 5.40278 3.51284 5.40234 3.91656V15.0834C5.40278 15.4872 5.55491 15.8742 5.82536 16.1597C6.09581 16.4452 6.46249 16.6057 6.84496 16.6062H12.6154C12.9979 16.6057 13.3646 16.4452 13.635 16.1597C13.9055 15.8742 14.0576 15.4872 14.058 15.0834V3.91656C14.0576 3.51284 13.9055 3.12579 13.635 2.84031C13.3646 2.55484 12.9979 2.39426 12.6154 2.3938ZM6.84496 3.40897H12.6154C12.7429 3.40912 12.8651 3.46265 12.9553 3.55781C13.0454 3.65297 13.0961 3.78198 13.0963 3.91656V4.42414H6.36409V3.91656C6.36423 3.78198 6.41494 3.65297 6.50509 3.55781C6.59524 3.46265 6.71747 3.40912 6.84496 3.40897ZM12.6154 15.591H6.84496C6.71747 15.5909 6.59524 15.5373 6.50509 15.4422C6.41494 15.347 6.36423 15.218 6.36409 15.0834V14.5759H13.0963V15.0834C13.0961 15.218 13.0454 15.347 12.9553 15.4422C12.8651 15.5373 12.7429 15.5909 12.6154 15.591Z"
            fill={fill}
          />
        </svg>
      );
    default:
      return null;
  }
};

export default React.memo(IconComponent);
