import { DropdownIndicatorProps, components } from 'react-select';

import { colors } from '#styles.variables';

import { Icon } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon
      name={IconNames['arrow-down']}
      width={24}
      height={24}
      fill={colors.black._10}
    />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
