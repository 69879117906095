import React from 'react';

import cn from 'classnames';

import { StyledRadioButton, StyledRadioButtonField } from './styles';

import {
  RadioButtonFieldProps,
  RadioButtonProps,
} from './types/RadioButton.types';

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  className,
  currentValue,
  disabled = false,
  onChange = () => {},
}) => (
  <StyledRadioButton
    {...{
      tabIndex: disabled ? -1 : 0,
      value,
      currentValue,
      disabled,
      onClick: () => !disabled && onChange(value),
      className: cn('radio-button', className),
    }}
  />
);

export const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
  name,
  value,
  className,
  ...props
}) => (
  <StyledRadioButtonField
    {...{
      name,
      value,
      type: 'radio',
      className: cn('radio-button', className),
      ...props,
    }}
  />
);

export default RadioButton;
