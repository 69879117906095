import { IconNames } from '#ui-kit.types';

export enum MenuItemType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type MenuItemProps = Readonly<{
  name: string;
  onClick: () => void;
  iconName: IconNames;
  type?: MenuItemType;
}>;
