import styled from 'styled-components';

import { ptr } from '#styles.utils';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
`;

export const Children = styled.main`
  max-width: ${ptr(1120)};
  margin: 0 auto;
  padding: ${ptr(28)} ${ptr(32)};
  min-height: calc(100vh - ${ptr(204)});
  display: flex;
  flex-direction: column;
`;
