import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';

export const Container = styled.footer`
  background-color: ${colors.black._02};
  border-top: ${ptr(1)} solid ${colors.black._02};
  padding: ${ptr(24)} ${ptr(32)};
`;

export const Copyright = styled(Typography)`
  text-align: center;
`;
