import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';
import { TypographySize } from '#ui-kit.types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled(Typography).attrs({
  size: TypographySize.s,
})`
  margin-left: ${ptr(8)};
`;
