import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { hexToRgbA, ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographyTag, TypographyWeight } from '#ui-kit.types';

export const Container = styled.header`
  width: ${ptr(256)};
  flex-shrink: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
  background-color: ${colors.black._00};
  border-right: ${ptr(1)} solid ${colors.black._02};
  z-index: 15;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const LogoContainer = styled.div<{ shifted: boolean }>`
  position: sticky;
  top: 0;
  background-color: ${colors.black._00};
  padding: ${ptr(14)} ${ptr(24)};
  transition: ${transition.average};
  z-index: 2;

  ${({ shifted }) =>
    shifted &&
    css`
      box-shadow: 0 ${ptr(4)} ${ptr(6)} ${colors.black._02};
    `}
`;

export const Navigation = styled.nav`
  margin-top: ${ptr(24)};
`;

export const Separator = styled.hr`
  margin: ${ptr(24)} ${ptr(32)};
  border: 0;
  border-top: ${ptr(1)} solid ${colors.black._02};
`;

export const NavigationAccordion = styled(NavLink)<{ isOpen: boolean }>`
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${ptr(16)} ${ptr(32)};
  padding-right: ${ptr(16)};
  position: relative;
  box-sizing: border-box;
  text-decoration: none;

  svg {
    transition: ${transition.average};

    path {
      transition: ${transition.average};
    }
  }

  &.active {
    background-color: ${hexToRgbA(colors.primary._100, 10)};

    + .accordion__indicator svg path,
    path {
      fill: ${colors.primary._100};
    }

    figure {
      background-color: ${colors.primary._100};
    }

    .navigation__name {
      color: ${colors.primary._100};
    }
  }
`;

export const NavigationChildren = styled.div`
  max-height: ${ptr(720)};
  transition: max-height ${transition.slow} ease-in-out;
  overflow: hidden;

  &[aria-expanded='true'] {
    max-height: 0;
    transition: max-height ${transition.slow} cubic-bezier(0, 1, 0, 1);
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const Toggle = styled.button<{ isOpen: boolean }>`
  cursor: pointer;
  position: absolute;
  top: ${ptr(18)};
  right: ${ptr(12)};
  transition: ${transition.average};

  svg {
    transition: ${transition.average};

    path {
      transition: ${transition.average};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(90deg);
      }
    `};
`;

export const NavigationItem = styled(NavLink)`
  padding: ${ptr(16)} ${ptr(32)};
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  background-color: ${colors.black._00};

  &.active {
    background-color: ${hexToRgbA(colors.primary._100, 10)};

    path {
      fill: ${colors.primary._100};
    }

    figure {
      background-color: ${colors.primary._100};
    }

    .navigation__name {
      color: ${colors.primary._100};
    }
  }
`;

export const NavigationChildContainer = styled(NavLink)`
  padding-left: ${ptr(68)};
  height: ${ptr(56)};
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 0;
  position: relative;
  transition: ${transition.average};

  &.active {
    background-color: ${hexToRgbA(colors.primary._100, 10)};

    figure {
      background-color: ${colors.primary._100};
    }

    .navigation__name {
      color: ${colors.primary._100};
    }
  }
`;

export const NavigationChildIndicator = styled.figure`
  width: ${ptr(4)};
  height: 100%;
  background-color: transparent;
  position: absolute;
  right: 0;
  border-top-left-radius: ${ptr(4)};
  border-bottom-left-radius: ${ptr(4)};
  transition: ${transition.average};
`;

export const RouteName = styled(Typography).attrs({
  tag: TypographyTag.span,
  color: colors.black._40,
  weight: TypographyWeight.Light,
})`
  margin-top: ${ptr(5)};
  margin-left: ${ptr(12)};
  transition: ${transition.average};
`;

export const NavigationGroup = styled.div`
  position: relative;
`;
