import React from 'react';

import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Icon } from '#ui-kit';
import {
  IconNames,
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';

const renderRightArrow = () =>
  `data:image/svg+xml;utf8,${encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      React.createElement(Icon, { name: IconNames['arrow-right'] }),
    ),
  )}`;

const renderLeftArrow = () =>
  `data:image/svg+xml;utf8,${encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      React.createElement(Icon, { name: IconNames['arrow-left'] }),
    ),
  )}`;

const renderClearIcon = () =>
  `data:image/svg+xml;utf8,${encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      React.createElement(Icon, { name: IconNames.cross }),
    ),
  )}`;

export const DatePickerWrapper = styled.div<{ focused: boolean }>`
  .react-datepicker-popper {
    padding-top: 0;
    z-index: 2;
  }

  .react-datepicker {
    border: ${ptr(1)} solid ${colors.black._10};
    border-radius: ${ptr(4)};
    color: ${colors.secondary._100};
    font-size: ${ptr(TypographySize.s)};

    &__current-month {
      display: none;
    }

    &__day-name,
    &__current-month,
    &__day {
      font-family: ${TypographyFamily.FactorA};
      text-transform: capitalize;
    }

    &__triangle {
      display: none;
    }

    &__header {
      background-color: ${colors.black._00};
      border-bottom: ${ptr(1)} solid ${colors.black._10};
      padding: ${ptr(16)} 0 ${ptr(8)};
    }

    &__day {
      :hover {
        background-color: ${colors.black._02};
      }
    }

    &__day-name,
    &__day {
      transition: ${transition.average};
      color: ${colors.secondary._100};
      width: ${ptr(40)};
      height: ${ptr(40)};
      margin: ${ptr(2)};
      line-height: ${ptr(44)};
      border-radius: ${ptr(4)};
    }

    &__day--weekend {
      color: ${colors.error._100};
    }

    &__day--outside-month {
      visibility: hidden;
    }

    &__day--disabled {
      color: ${colors.black._10};
    }

    &__day--selected {
      color: ${colors.black._00};
      background-color: ${colors.primary._100};

      :hover {
        box-shadow: 0 ${ptr(4)} ${ptr(6)} ${colors.black._02};
        background-color: ${colors.primary._80};
      }
    }

    &__month-dropdown-container,
    &__year-dropdown-container {
      margin: 0;

      select {
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0;
        text-overflow: '';
        font-family: ${TypographyFamily.FactorA};
        font-size: ${ptr(TypographySize.m)};
        font-weight: ${TypographyWeight.Medium};
        text-transform: capitalize;
        cursor: pointer;
      }
    }

    &__month-dropdown-container {
      margin-right: ${ptr(8)};

      select {
        text-align: center;
      }
    }

    &__close-icon {
      z-index: 1;
      height: ${ptr(60)};
      width: ${ptr(24)};
      height: ${ptr(24)};
      padding: 0;
      right: ${ptr(9)};
      top: ${ptr(18)};
      position: absolute;
      transition: ${transition.average};
      opacity: ${({ focused }) => (focused ? 1 : 0)};

      &::after {
        content: url(${renderClearIcon()});
        padding: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }

    &__navigation--next {
      width: ${ptr(32)};
      height: ${ptr(32)};
      text-indent: 0;
      right: ${ptr(8)};
      top: ${ptr(12)};
    }

    &__navigation-icon--next {
      width: inherit;
      height: inherit;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;

      ::before {
        content: url(${renderRightArrow()});
        border: 0;
        transform: initial;
        width: inherit;
        height: inherit;
        left: 0;
        top: 0;
      }
    }

    &__navigation--previous {
      width: ${ptr(32)};
      height: ${ptr(32)};
      text-indent: 0;
      left: ${ptr(8)};
      top: ${ptr(12)};
    }

    &__navigation-icon--previous {
      width: inherit;
      height: inherit;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;

      ::before {
        content: url(${renderLeftArrow()});
        border: 0;
        transform: initial;
        width: inherit;
        height: inherit;
        left: 0;
        top: 0;
      }
    }
  }
`;
