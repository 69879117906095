import React, { useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '#hooks';

import { LoadingContent } from '#components';

import { fetchUserThunk } from '#store/user/thunks';
import { UserStatus } from '#store/user/types/user.types';

import AuthService from '#services/auth';

import PrivateRoutes from './private';
import PublicRoutes from './public';

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Routing: React.FC = () => {
  const authService = new AuthService();

  const { status } = useAppSelector(({ user }) => user);

  const dispatch = useAppDispatch();

  const getUserData = async () => {
    dispatch(fetchUserThunk());
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (
    status === UserStatus.Loading ||
    (status === UserStatus.Idle && authService.getAccessToken())
  ) {
    return React.createElement(
      StyledContainer,
      {},
      React.createElement(LoadingContent),
    );
  }

  return React.createElement(
    Router,
    {},
    status === UserStatus.Successed
      ? React.createElement(PrivateRoutes)
      : React.createElement(PublicRoutes),
  );
};
export default Routing;
