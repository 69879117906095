import { ptr } from '#styles.utils';

import { colors } from '#styles.variables';
import { Typography } from '#ui-kit';
import {
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { OrderDeliveryTypeEnum } from '#services/orders/enums/order-delivery-type.enum';
import {
  OrderResponse,
} from '#services/orders/responses/order.response';
import OrderPrices from '../OrderPrice';
import OrderProductLine from '../ProductLine';
import * as Styled from './style';

// import { LoadingType } from 'pages/Order';
// import { formatPrice } from 'utils/formatPrice';

type RefundItemMetadata = {
  max: number;
  curr: number;
  fullQuantity: number;
  pricePerItem: number;
};

type ViewMode = {
    mode: 'REFUND';
    priceToRefund: number;
    refundItemsMap: Record<number, RefundItemMetadata>;
    isRefundDeliveryPrice: boolean;
    onAddItemToRefund: (lineId: number, quantity: number, itemFullPrice: number) => void;
    onDeleteItemToRefund: (lineId: number, quantity: number, itemFullPrice: number) => void;
    onRefundDeliveryPrice: () => void,
  } | {
    mode: 'VIEW';
  };

type ViewProps = {
  data: OrderResponse;
  options: ViewMode;
};

const View: React.FC<ViewProps> = ({
  data,
  options,
}) => (
  <>
    {
      options.mode === 'VIEW' && <Typography
        size={TypographySize.m}
        tag={TypographyTag.p}
        style={{
          textAlign: 'center',
          marginBottom: ptr(20),
        }}
      >
        * Нажми на цену или количество продукта, чтобы узнать подробнее 
      </Typography>
    }
    <Styled.Container>
      <Typography
        size={TypographySize.l}
        tag={TypographyTag.h3}
        style={{
          marginBottom: ptr(20),
          textAlign: 'center',
          backgroundColor: colors.primary._05,
          borderRadius: ptr(8),
          lineHeight: ptr(40),
        }}
      >
        Чек
      </Typography>
      <Styled.Receipt>
        {[
          ...data.items?.map((item) => <OrderProductLine key={'line-' + item.lineId} options={options} data={{
            id: item.id,
            name: item.name,
            lineId: item.lineId,
            images: item.images,
            prices: item.prices,
            quantity: item.quantity,
            refundedQuantity: item.refundedQuantity,
            appliedPromotions: item.appliedPromotions,
            type: 'PRODUCT',
            subLines: item.modifiers?.map((modifier) => ({
              id: modifier.id,
              name: modifier.name,
              lineId: modifier.lineId,
              images: [],
              prices: modifier.prices,
              quantity: modifier.quantity,
              refundedQuantity: modifier.refundedQuantity,
              appliedPromotions: modifier.appliedPromotions,
              type: 'MODIFIER',
            })),
          }}/>),
          ...data.coupons?.map((promotion) => <OrderProductLine key={'line-' + promotion.lineId}  options={options} data={{
            id: promotion.mindboxId,
            name: promotion.name,
            lineId: promotion.lineId,
            images: promotion.imageFileId
              ? [`${process.env.REACT_APP_API_MOBILE_URL}/files/${promotion.imageFileId}`]
              : [],
            prices: promotion.prices,
            quantity: promotion.quantity,
            refundedQuantity: promotion.refundedQuantity,
            appliedPromotions: promotion.appliedPromotions,
            type: 'COUPON',
            subLines: promotion?.menuProduct?.modifiers.map((modifier, indx) => ({
              id: modifier.id,
              name: modifier.name,
              lineId: indx,
              images: [],
              prices: {
                basePrice: 0,
                discountedPrice: 0,
                fullBasePrice: 0,
                fullDiscountedPrice: 0,
              },
              quantity: modifier.quantity,
              refundedQuantity: 0,
              appliedPromotions: promotion.appliedPromotions,
              type: 'COUPON_PRODUCT',
            })),
          }}/>) || [],
        ]}
      </Styled.Receipt>
      {
        data.deliveryType === OrderDeliveryTypeEnum.delivery && options.mode === 'REFUND'
        ? <Typography
          size={TypographySize.m}
          tag={TypographyTag.p}
          weight={TypographyWeight.Light}
          
          style={{
            textAlign: 'center',
            margin: `${ptr(22)} 0`,
          }}
        >
          <Styled.Checkbox
            onChange={options.onRefundDeliveryPrice}
            checked={options.isRefundDeliveryPrice}
            type='checkbox'
            disabled={data.isDeliveryRefunded}
          />
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{
              marginLeft: ptr(6),
            }}
          >Вернуть средства за доставку</Typography>
        </Typography>
        : <></>
      }
      {
        options.mode === 'REFUND' 
        ? <OrderPrices data={data.prices} options={{ mode: options.mode, priceToRefund: options.priceToRefund }}/>
        : <OrderPrices data={data.prices} options={{ mode: options.mode }}/>
      }
    </Styled.Container>
  </>
);

export default View;
