export const enum OrderPaymentStatusEnum {
  NEW = 'NEW',

  FORM_SHOWED = 'FORM_SHOWED',

  '3DS_CHECKING' = '3DS_CHECKING',

  '3DS_CHECKED' = '3DS_CHECKED',

  AUTHORIZING = 'AUTHORIZING',

  AUTHORIZED = 'AUTHORIZED',

  CONFIRMED = 'CONFIRMED',

  COMPLETED = 'COMPLETED',

  REJECTED = 'REJECTED',

  CANCELED = 'CANCELED',

  PARTIAL_REVERSED = 'PARTIAL_REVERSED',

  REVERSED = 'REVERSED',

  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',

  REFUNDED = 'REFUNDED',
}
