import { ptr } from '#styles.utils';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { StatusBadge } from '#components';
import { notAvailableValue } from '#utils';
import { OrderPaymentEnum } from '#services/orders/enums/order-payment.enum';
import { OrderPaymentsResponseType } from '#services/orders/responses/order-payments.response';
import * as Styled from './style';
import { OrderPaymentTypeBadgeMap } from 'pages/Order/constants';

type ViewProps = {
  data: OrderPaymentsResponseType;
};

const orderPaymentsLocalizationMap: Record<keyof OrderPaymentsResponseType, string> = {
  bankCardId: 'Идентификатор карты',
  bankCardMask: 'Номер карты',
  paymentType: 'Способ оплаты',
};

const getPaymentsValue = <T extends keyof OrderPaymentsResponseType>(
  paymentsKey: keyof OrderPaymentsResponseType,
  value: OrderPaymentsResponseType[T],
) => paymentsKey === 'paymentType'
    ? <Styled.OrderStatusContainer>
        <StatusBadge<typeof OrderPaymentEnum>
          {...{
            value: value as OrderPaymentEnum,
            data: OrderPaymentTypeBadgeMap,
          }}
        />
      </Styled.OrderStatusContainer>
    : <Typography
        size={TypographySize.m}
        tag={TypographyTag.span}
        weight={TypographyWeight.Medium}
        key={`payments-${paymentsKey}-value`}
      >
        {notAvailableValue(value)}
      </Typography>;

const OrderPayments: React.FC<ViewProps> = ({
  data,
}) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginBottom: ptr(20) }}
    >
      Информация о платеже
    </Typography>
    <Table
      {...{
        // loading,
        header: {
          cells: {},
        },
        rows: Object.keys(orderPaymentsLocalizationMap).map((paymentsKey) => ({
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                  key={`payments-${paymentsKey}`}
                >
                  {orderPaymentsLocalizationMap[paymentsKey as keyof OrderPaymentsResponseType]}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                getPaymentsValue(paymentsKey as keyof OrderPaymentsResponseType, data[paymentsKey as keyof OrderPaymentsResponseType])
              ),
            },
          },
        })),
      }}
    />
  </>
);

export default OrderPayments;
