import apiService from '#services/api';
import debug from '#services/debug.service';

import { AcceptInviteDto } from './dto/accept-invite.dto';
import { GetUsersDto } from './dto/get-users.dto';
import { InviteUserDto } from './dto/invite-user.dto';
import { UpdateEmailDto } from './dto/update-email.dto';
import { UpdateUserDto } from './dto/update-user.dto';
import { UserResponse } from './responses/user.response';

export class UserService {
  private readonly API_ROUTES = {
    user: 'user/',
    users: 'user/crm/',
    email: 'user/crm/email/',
    resetEmail: 'user/crm/email/reset/',
    acceptInvite: 'user/crm/confirm',
  };

  public async getCurrentUser() {
    try {
      const request = await apiService.request.get(this.API_ROUTES.user, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result: UserResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ UserService ~ getCurrentUser ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ getCurrentUser ~ err:', err);

      throw err;
    }
  }

  public async getUser(userId: string) {
    try {
      const request = await apiService.request.get(
        `${this.API_ROUTES.users}${userId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: UserResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ UserService ~ getUser ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ getUser ~ err:', err);

      throw err;
    }
  }

  public async updateEmail(userId: string, payload: UpdateEmailDto) {
    try {
      await apiService.request.post(`${this.API_ROUTES.email}${userId}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      debug.success('🧑🏻‍💻 ~ UserService ~ updateEmail ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ updateEmail ~ err:', err);

      throw err;
    }
  }

  public async resetEmail(userId: string) {
    try {
      await apiService.request.post(`${this.API_ROUTES.resetEmail}${userId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('🧑🏻‍💻 ~ UserService ~ resetEmail ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ resetEmail ~ err:', err);

      throw err;
    }
  }

  public async deleteUser(userId: string) {
    try {
      await apiService.request.delete(`${this.API_ROUTES.users}${userId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('🧑🏻‍💻 ~ UserService ~ deleteUser ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ deleteUser ~ err:', err);

      throw err;
    }
  }

  public async updateUser(userId: string, payload: UpdateUserDto) {
    try {
      const request = await apiService.request.patch(
        `${this.API_ROUTES.users}${userId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      const result: UserResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ UserService ~ updateUser ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ updateUser ~ err:', err);

      throw err;
    }
  }

  public async inviteUser(payload: InviteUserDto) {
    try {
      await apiService.request.post(`${this.API_ROUTES.users}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      debug.success('🧑🏻‍💻 ~ UserService ~ inviteUser ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ inviteUser ~ err:', err);

      throw err;
    }
  }

  public async acceptInvite(payload: AcceptInviteDto) {
    try {
      await apiService.request.post(`${this.API_ROUTES.acceptInvite}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      debug.success('🧑🏻‍💻 ~ UserService ~ acceptInvite ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UserService ~ acceptInvite ~ err:', err);

      throw err;
    }
  }

  public async getUsers({
    status,
    search = '',
    role,
    ...searchParams
  }: GetUsersDto) {
    try {
      const request = await apiService.request.get(`${this.API_ROUTES.users}`, {
        headers: {
          'content-type': 'application/json',
        },
        searchParams: {
          search,
          ...(() => {
            if (role) {
              return { ...{ role: role.join(',') } };
            }
            return {};
          })(),
          ...searchParams,
          ...(() => {
            if (status) {
              return { ...{ status } };
            }

            return {};
          })(),
        },
      });

      const result: UserResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ UsersService ~ getUsers ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ UsersService ~ getUsers ~ err:', err);

      throw err;
    }
  }
}
