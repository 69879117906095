import React from 'react';

import * as Styled from './styles';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Spinner } from '#ui-kit';
import { SpinnerSize } from '#ui-kit.types';

import TableCell from '../TableCell';
import { TableCellTag } from '../TableCell/types/TableCell.types';
import { TableLoadingRowProps } from './types/TableLoadingRow.types';

const TableLoadingRow: React.FC<TableLoadingRowProps> = ({ header }) => {
  const numberOfCells = Object.keys(header.cells).length;

  return (
    <TableCell
      tag={TableCellTag.tr}
      border={{
        width: 1,
        style: 'solid',
        color: colors.black._02,
        sides: ['top'],
      }}
    >
      <TableCell
        {...{ colSpan: numberOfCells, padding: `${ptr(14)} ${ptr(24)}` }}
      >
        <Styled.Container>
          <Spinner size={SpinnerSize.Medium} color={colors.secondary._100} />
          <Styled.LoadingText>Подгружаем данные…</Styled.LoadingText>
        </Styled.Container>
      </TableCell>
    </TableCell>
  );
};

export default TableLoadingRow;
