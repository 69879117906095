export const prettifyDate = (dateStr: string | Date | null): string => {
    if (!dateStr) {
        return 'N/A';
    }

    if (typeof dateStr === 'string') {
        const date = new Date(dateStr);

        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    return dateStr.toLocaleDateString() + ' ' + dateStr.toLocaleTimeString();
};
