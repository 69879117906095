import ky from 'ky';

import AuthService from '#services/auth';
import debug from '#services/debug.service';

const authService = new AuthService();

class ApiService {
  public readonly request;

  public readonly apiPath = process.env.REACT_APP_API_MOBILE_URL;

  public readonly httpStatus = {
    success: 200,
  };

  constructor() {
    this.request = ky.extend({
      retry: {
        limit: 1,
        methods: ['get', 'post', 'put', 'delete', 'patch'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
      },
      prefixUrl: this.apiPath,
      timeout: false,
      hooks: {
        beforeRequest: [ApiService.authorizationBeforeRequest],
      },
    });
  }

  private static async authorizationBeforeRequest(request: Request) {
    try {
      const accessToken = authService.getAccessToken();

      if (accessToken) {
        request.headers.set('Authorization', `Bearer ${accessToken}`);
      }
    } catch (err) {
      debug.error('Failed to set token before request hook', err);

      throw err;
    }
  }
}

const apiService = new ApiService();
export default apiService;
