import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';


// import { ptr } from '#styles.utils';
// import { colors } from '#styles.variables';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    line-height: auto;
`;

export const PlusButton = styled.div`{
    width: ${ptr(16)};
    height: ${ptr(16)};
    background-color: ${colors.primary._80};
    text-align: center;
    line-height: ${ptr(16)};
    border-radius: ${ptr(4)};
    color: ${colors.black._00};

    :focus {
        transform: scale(1.2, 1.2);
    }
}`;

export const MinusButton = styled.div`{
    width: ${ptr(16)};
    height: ${ptr(16)};
    background-color: ${colors.error._100};
    text-align: center;
    line-height: ${ptr(16)};
    border-radius: ${ptr(4)};
    color: ${colors.black._00};

    :focus {
        transform: scale(1.2, 1.2);
        outline: none;
      }
}`;