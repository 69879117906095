import apiService from '#services/api';
import debug from '#services/debug.service';
import { PromotionDto } from './dto/get-promotion.dto';
import { GetPromotionsProductsDto } from './dto/get-promotions-products.dto';

import { PromotionsDto } from './dto/get-promotions.dto';
import { UpdatePromotionDto } from './dto/update-promotion.dto';
import { PromotionResponse } from './responses/get-promotion.response';
import { GetPromotionsProductsResponse } from './responses/get-promotions-products.response';
import { PromotionsResponse } from './responses/get-promotions.response';

export class PromotionsService {
  private readonly API_ROUTES = {
    promotions: 'admin/promotions',
    promotionById: (id: number) => `admin/promotions/${id}`,
    promotionsProducts: (id: number) => `admin/promotions/${id}/products`,
  };

  public async getPromotions({ page, size, type, mindboxId }: PromotionsDto) {
    const requestString = `${this.API_ROUTES.promotions}?page=${page}&size=${size}&type=${type}`;
    try {
      const request = await apiService.request.get(
        !!mindboxId?.length
          ? requestString + `&mindboxId=${mindboxId}`
          : requestString,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: PromotionsResponse = await request.json();

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ PromotionsService ~ getPromotions ~ err:', err);
      throw err;
    }
  }

  public async getPromotionById({ promotionId }: PromotionDto) {
    try {
      const request = await apiService.request.get(
        `${this.API_ROUTES.promotionById(promotionId)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: PromotionResponse = await request.json();

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ PromotionsService ~ getPromotionById ~ err:', err);
      throw err;
    }
  }

  public async updatePromotionById({ data, promotionId }: UpdatePromotionDto) {
    try {
      const request = await apiService.request.patch(
        `${this.API_ROUTES.promotionById(promotionId)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      const result: PromotionResponse = await request.json();

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ PromotionsService ~ updatePromotionById ~ err:', err);
      throw err;
    }
  }

  public async getPromotionsProducts({
    promotionId,
    restaurantId,
  }: GetPromotionsProductsDto) {
    try {
      const request = await apiService.request.get(
        `${this.API_ROUTES.promotionsProducts(
          promotionId,
        )}/?restaurantId=${restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const result: GetPromotionsProductsResponse = await request.json();
      return result;
    } catch (err) {
      debug.error(
        '🧑🏻‍💻 ~ PromotionsService ~ getPromotionsByRestaurant ~ err:',
        err,
      );
      throw err;
    }
  }
}
