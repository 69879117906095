import React from 'react';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';

import { FormSectionProps } from './types/FormSection.types';

const FormSection: React.FC<FormSectionProps> = ({
  label,
  isRequired,
  description,
  children,
}) => (
  <Styled.Container>
    <Styled.Aside>
      <Styled.Label>
        {label}
        {isRequired && (
          <Styled.Marker tag={TypographyTag.span} color={colors.error._100}>
            {' '}
            *
          </Styled.Marker>
        )}
      </Styled.Label>
      {description && (
        <Styled.Description
          weight={TypographyWeight.Light}
          size={TypographySize.s}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Styled.Description>
      )}
    </Styled.Aside>
    <Styled.Content>
      <Styled.Children>{children}</Styled.Children>
    </Styled.Content>
  </Styled.Container>
);

export default FormSection;
