import * as Styled from './styles';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { StatusBadge } from '#components';
import { StatusBadgeVariant } from '#components.types';

import { formatPhoneNumber, formatRoleString, notAvailableValue } from '#utils';

import { UserStatus } from '#services/user/responses/user.response';

import { TableProps } from './types/Table.types';

const Component: React.FC<TableProps> = ({
  data: { data },
  preLoading,
  loading,
}) => (
  <Table
    {...{
      preLoading,
      loading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Имя администратора
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Электронная почта
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Номер телефона
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Роль
              </Typography>
            ),
          },
          4: {
            width: 140,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Статус
              </Typography>
            ),
          },
        },
      },
      rows: data.map(
        ({ name, id, email, phoneNumber, role, status: value }) => ({
          to: `/users/admins/${id}`,
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(name)}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(email)}
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {formatPhoneNumber(notAvailableValue(phoneNumber))}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {formatRoleString(role)}
                </Typography>
              ),
            },
            4: {
              width: 140,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Styled.UserStatusContainer>
                  <StatusBadge<typeof UserStatus>
                    {...{
                      value,
                      data: {
                        [UserStatus.ACTIVE]: {
                          label: 'Активный',
                          variant: StatusBadgeVariant.Active,
                        },
                        [UserStatus.DISABLED]: {
                          label: 'Деактивирован',
                          variant: StatusBadgeVariant.Warning,
                        },
                        [UserStatus.BLOCKED]: {
                          label: 'Заблокирован',
                          variant: StatusBadgeVariant.Disabled,
                        },
                        [UserStatus.DELETED]: {
                          label: 'Удален',
                          variant: StatusBadgeVariant.Blocked,
                        },
                      },
                    }}
                  />
                </Styled.UserStatusContainer>
              ),
            },
          },
        }),
      ),
    }}
  />
);

export default Component;
