import { Field } from 'formik';
import styled, { css } from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import {
  RadioButtonFieldProps,
  RadioButtonProps,
} from './types/RadioButton.types';

const DefaultStyles = styled.div<RadioButtonProps>(
  ({ disabled, currentValue, value }) => {
    const isChecked = currentValue === value;

    return {
      width: ptr(24),
      height: ptr(24),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.black._00,
      transition: transition.average,
      borderRadius: ptr(20),
      cursor: 'pointer',
      border: `${ptr(1)} solid`,
      opacity: disabled ? 0.4 : 1,
      borderColor: isChecked ? colors.primary._80 : colors.black._10,
    };
  },
);

export const StyledRadioButton = styled(DefaultStyles)`
  ::before {
    content: '';
    width: ${ptr(18)};
    height: ${ptr(18)};
    border-radius: ${ptr(12)};
    transition: ${transition.average};
    background-color: ${({ currentValue, value }) =>
      currentValue === value ? colors.primary._80 : 'transparent'};
  }

  ${({ currentValue, value }) => {
    const isChecked = currentValue === value;

    return css`
      :focus {
        border: ${ptr(1)} solid
          ${isChecked ? colors.primary._100 : colors.black._20};

        ::before {
          background-color: ${isChecked ? colors.primary._100 : 'transparent'};
        }
      }
    `;
  }}
`;

export const StyledRadioButtonField = styled(Field)<RadioButtonFieldProps>`
  &[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${colors.black._00};
    width: ${ptr(24)};
    height: ${ptr(24)};
    border: ${ptr(1)} solid ${colors.black._10};
    border-radius: ${ptr(20)};
    display: grid;
    place-content: center;
    cursor: pointer;
    transition: ${transition.average};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }

  &[type='radio']::before {
    content: '';
    width: ${ptr(18)};
    height: ${ptr(18)};
    border-radius: ${ptr(12)};
    transform: scale(0);
    transition: ${transition.average} ease-in-out;
    background-color: ${colors.primary._80};
  }

  &[type='radio']:checked {
    border-color: ${colors.primary._80};
  }

  &[type='radio']:checked::before {
    transform: scale(1);
  }

  &[type='radio']:focus {
    border-color: ${colors.black._20};
  }

  &[type='radio']:checked:focus {
    border: ${ptr(1)} solid ${colors.primary._100};

    ::before {
      background-color: ${colors.primary._100};
    }
  }
`;
