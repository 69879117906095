import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { notAvailableValue } from '#utils';
import { PromotionStatusEnum } from '#services/promotions/dto/get-promotions.dto';

import { type TableProps } from './types/Table.types';

const Form: React.FC<TableProps> = ({ data, loading }) => (
  <>
    <Table
      {...{
        loading,
        header: {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Название
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  ID
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Тип акции
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Активна
                </Typography>
              ),
            },
            4: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Вывод на главный экран
                </Typography>
              ),
            },
            5: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Приоритет
                </Typography>
              ),
            },
          },
        },
        rows: data?.map(
          ({
            id,
            name,
            internalStatus,
            mindboxId,
            type,
            isShownOnMainPage,
            sortOrder,
          }) => ({
            to: `/promotions/${id}`,
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(name)}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(mindboxId)}
                  </Typography>
                ),
              },
              2: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(type)}
                  </Typography>
                ),
              },
              3: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(
                      internalStatus === PromotionStatusEnum.ACTIVE
                        ? 'Да'
                        : 'Нет',
                    )}
                  </Typography>
                ),
              },
              4: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(isShownOnMainPage ? 'Да' : 'Нет')}
                  </Typography>
                ),
              },
              5: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(sortOrder)}
                  </Typography>
                ),
              },
            },
          }),
        ),
      }}
    />
  </>
);

export default Form;
