export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default',
}

export type ExcludedDefaultSorting = Exclude<
  SortingOrder,
  SortingOrder.DEFAULT
>;
