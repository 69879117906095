import { ButtonProps } from '#ui-kit.types';

import { SwitcherProps } from '#components.types';

export enum HeadUnderlineVariant {
  Primary = '1',
  Secondary = '2',
  Tertiary = '3',
}

export type HeadProps = Readonly<
  Partial<{
    label: string;
    button: ButtonProps &
      Readonly<
        Partial<{
          to: string;
        }>
      >;
    underlineVariant: HeadUnderlineVariant;
    switcher: SwitcherProps;
  }>
>;
