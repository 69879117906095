import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const PricesContainer = styled.div`
  padding: ${ptr(10)};
  width: 50%;
  margin-top: ${ptr(20)};
  margin-left: auto;
  background-color: ${colors.primary._05};
  border-radius: ${ptr(8)};
`;

export const PriceLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
