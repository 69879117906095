import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useField } from 'formik';

import * as Styled from '../input.styles';
import { colors } from '#styles.variables';

import { Icon } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

import ClearButton from '../../common/ClearButton';
import Counter from '../../common/Counter';
import Error from '../../common/Error';
import { InputFieldProps, InputProps, InputTypes } from './types/Input.types';

const PASSWORD_VISIBILITY_DELAY = 3000;

const Input: React.FC<InputProps> = React.memo(
  ({
    placeholder = ' ',
    name,
    label = '',
    disabled = false,
    readOnly = false,
    className,
    maxLength,
    type = InputTypes.Text,
    value,
    onChange: handleChange,
    symbolCounter = false,
    clearable = false,
    parentClassName,
    touched,
    error,
    icon,
  }) => {
    const ref = useRef<HTMLInputElement>();

    const [cleared, setCleared] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    let timeoutId: NodeJS.Timeout;

    const onChange = ({
      target: { value: output },
    }: {
      target: { value: string };
    }) => {
      if (handleChange) handleChange(output);
    };

    const onClear = (data: { target: { value: string } }) => {
      onChange(data);
      setCleared(true);
      setTimeout(() => setCleared(false), 0);
    };

    const showPassword = () => {
      setPasswordVisibility(!passwordVisibility);
    };

    useEffect(() => {
      if (passwordVisibility) {
        timeoutId = setTimeout(
          () => setPasswordVisibility(false),
          PASSWORD_VISIBILITY_DELAY,
        );
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, [passwordVisibility]);

    return (
      <Styled.Container
        {...{ className: cn('input-container', parentClassName) }}
      >
        <Styled.Wrapper {...{ disabled, className: cn('input', className) }}>
          <Styled.Input
            {...{
              ref: ref as React.RefObject<HTMLInputElement>,
              placeholder,
              name,
              label,
              disabled,
              readOnly,
              maxLength,
              type:
                type === InputTypes.Password && passwordVisibility
                  ? InputTypes.Text
                  : type,
              value,
              onChange,
              clearable,
              icon,
            }}
          />
          <Styled.Label>{label}</Styled.Label>
          {icon && (
            <Styled.IconContainer>
              <Icon name={icon} fill={colors.black._10} />
            </Styled.IconContainer>
          )}
          {type === InputTypes.Password && (
            <Styled.InputButton
              className="password-visibility"
              onClick={showPassword}
            >
              <Icon
                name={
                  passwordVisibility ? IconNames['closed-eye'] : IconNames.eye
                }
                width={24}
                height={24}
              />
            </Styled.InputButton>
          )}
          {clearable && !readOnly && type !== InputTypes.Password && (
            <ClearButton {...{ inputRef: ref, onClear }} />
          )}
        </Styled.Wrapper>
        <Styled.BottomWrapper>
          <Error {...{ error, touched }} />
          {symbolCounter && maxLength && (
            <Counter {...{ inputRef: ref, maxLength, cleared }} />
          )}
        </Styled.BottomWrapper>
      </Styled.Container>
    );
  },
);

const InputField: React.FC<InputFieldProps> = React.memo((props) => {
  const [{ value: initialValue }, { error, touched }, { setValue }] =
    useField<string>(props.name);

  const [value, onChange] = useState(initialValue);

  useEffect(() => {
    setValue(value, true);
  }, [value]);

  return (
    <Input
      {...{
        ...props,
        value,
        onChange,
        error,
        touched,
      }}
    />
  );
});

export { InputField };

export default Input;
