import * as Styled from './styles';
import { colors } from '#styles.variables';

import { Spinner } from '#ui-kit';
import { SpinnerSize } from '#ui-kit.types';

const LoadingContent = () => (
  <Styled.Container>
    <Spinner color={colors.secondary._100} size={SpinnerSize.Medium} />
    <Styled.LoadingLabel>Загрузка…</Styled.LoadingLabel>
  </Styled.Container>
);

export default LoadingContent;
