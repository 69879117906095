import styled from 'styled-components';

import { hexToRgbA, ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Typography } from '#ui-kit';

import { ButtonProps, ButtonVariant, ButtonSize } from './types/Button.types';

const BUTTON_SIZE = {
  [ButtonSize.Large]: `${ptr(20)} ${ptr(16)}`,
  [ButtonSize.Medium]: `${ptr(14)} ${ptr(16)}`,
  [ButtonSize.Small]: `${ptr(8)} ${ptr(12)}`,
  [ButtonSize.ExtraSmall]: `${ptr(0)} ${ptr(0)}`,
};

const generateColor = (variant?: ButtonVariant) => {
  if (variant === ButtonVariant.Primary) {
    return {
      background: colors.primary._100,
      color: colors.black._00,
      border: `${ptr(1)} solid ${colors.primary._100}`,

      ':hover': {
        background: colors.primary._100,
        borderColor: colors.primary._100,
      },

      ':active': {
        background: colors.primary._100,
        borderColor: colors.primary._100,
      },
    };
  }

  if (variant === ButtonVariant.Error) {
    return {
      background: colors.error._100,
      color: colors.black._00,
      border: `${ptr(1)} solid ${colors.error._100}`,

      ':hover': {
        background: colors.error._100,
        borderColor: colors.error._100,
      },

      ':active': {
        background: colors.error._100,
        borderColor: colors.error._100,
      },
    };
  }

  if (variant === ButtonVariant.Secondary) {
    return {
      background: colors.secondary._100,
      color: colors.black._00,
      border: `${ptr(1)} solid ${colors.secondary._100}`,

      ':hover': {
        background: colors.secondary._80,
        borderColor: colors.secondary._80,
      },

      ':active': {
        background: colors.secondary._100,
        borderColor: colors.secondary._100,
      },
    };
  }

  return {
    background: colors.black._00,
    color: colors.secondary._100,
    border: `${ptr(1)} solid ${colors.primary._100}`,

    ':hover': {
      background: hexToRgbA(colors.primary._100, 10),
      borderColor: colors.primary._80,
    },

    ':active': {
      background: colors.primary._05,
      borderColor: colors.primary._100,
    },
  };
};

export const Button = styled.button<ButtonProps>(
  ({ variant, size = ButtonSize.Large, isLoading, disabled }) => {
    const {
      background,
      border,
      ':hover': { background: hoverBackground, borderColor: hoverBorderColor },
      ':active': {
        background: activeBackground,
        borderColor: activeBorderColor,
      },
    } = generateColor(variant);

    return {
      border,
      background,
      padding: BUTTON_SIZE[size],
      borderRadius: ptr(4),
      transition: transition.average,
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      opacity: disabled ? 0.4 : 1,

      ':hover, :focus': !isLoading
        ? {
            borderColor: hoverBorderColor,
            background: hoverBackground,
            boxShadow: `0 0 ${ptr(4)} ${hexToRgbA(colors.black._100, 40)}`,
          }
        : {},

      ':active': {
        background: activeBackground,
        borderColor: activeBorderColor,
      },

      '.spinner': {
        position: 'absolute',
      },
    };
  },
);

export const Label = styled(Typography)<ButtonProps>(
  ({ variant, isLoading }) => {
    const { color } = generateColor(variant);

    return {
      color,
      marginTop: ptr(4),
      lineHeight: 'auto',
      opacity: isLoading ? 0 : 1,
    };
  },
);
