import * as Styled from './styles';

import { Button } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { Switcher } from '#components';

import { HeadProps, HeadUnderlineVariant } from './types/Head.types';

const Head: React.FC<HeadProps> = ({
  label,
  button,
  switcher,
  underlineVariant = HeadUnderlineVariant.Tertiary,
}) => (
  <Styled.Container>
    <Styled.Label
      weight={TypographyWeight.Bold}
      size={TypographySize.xxl}
      tag={TypographyTag.h1}
    >
      {label}

      <Styled.Underline
        underlineVariant={underlineVariant}
        src={`/assets/images/underlines/underline(${underlineVariant}).png`}
      />
    </Styled.Label>
    {switcher && (
      <Styled.TabContainer>
        <Switcher {...switcher} />
      </Styled.TabContainer>
    )}
    <Styled.ButtonContainer>
      {button && (
        <>
          {button.to ? (
            <Styled.ButtonLink to={button.to}>
              <Button
                {...{
                  size: ButtonSize.Medium,
                  type: 'button',
                  variant: ButtonVariant.Primary,
                  ...button,
                }}
              />
            </Styled.ButtonLink>
          ) : (
            <Styled.Button>
              <Button
                {...{
                  size: ButtonSize.Medium,
                  type: 'button',
                  variant: ButtonVariant.Primary,
                  ...button,
                }}
              />
            </Styled.Button>
          )}
        </>
      )}
    </Styled.ButtonContainer>
  </Styled.Container>
);

export default Head;
