import cn from 'classnames';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { RadioButton, RadioButtonField } from '#ui-kit';
import { TypographySize, TypographyWeight } from '#ui-kit.types';

import {
  DefaultRadioButtonFormProps,
  RadioButtonFieldFormProps,
  RadioButtonFormProps,
} from './types/RadioButtonForm.types';

const Container: React.FC<DefaultRadioButtonFormProps> = ({
  title,
  subtitle,
  children,
  parentClassName,
}) => (
  <Styled.Container className={cn('radio-button-form', parentClassName)}>
    <Styled.RadioButtonContainer>{children}</Styled.RadioButtonContainer>
    <Styled.TextContainer>
      <Styled.Label weight={TypographyWeight.Medium}>{title}</Styled.Label>
      {subtitle && (
        <Styled.Subtitle
          weight={TypographyWeight.Light}
          size={TypographySize.s}
          color={colors.black._40}
        >
          {subtitle}
        </Styled.Subtitle>
      )}
    </Styled.TextContainer>
  </Styled.Container>
);

const RadioButtonForm: React.FC<RadioButtonFormProps> = ({
  title,
  subtitle,
  parentClassName,
  ...props
}) => (
  <Container {...{ title, subtitle, parentClassName }}>
    <RadioButton {...props} />
  </Container>
);

export const RadioButtonFieldForm: React.FC<RadioButtonFieldFormProps> = ({
  title,
  subtitle,
  parentClassName,
  ...props
}) => (
  <Container {...{ title, subtitle, parentClassName }}>
    <RadioButtonField {...props} />
  </Container>
);

export default RadioButtonForm;
