/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  SIZING_STYLE,
  SizingData,
  SizingProps,
} from './types/getSizingData.types';

const isIE =
  typeof document !== 'undefined'
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !!(document.documentElement as any).currentStyle
    : false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pick = <Obj extends { [key: string]: any }, Key extends keyof Obj>(
  props: Key[],
  obj: Obj,
): Pick<Obj, Key> =>
  props.reduce((acc, prop) => {
    acc[prop] = obj[prop];
    return acc;
  }, {} as Pick<Obj, Key>);

export const getSizingData = (node: HTMLElement): SizingData | null => {
  const style = window.getComputedStyle(node);

  if (style === null) {
    return null;
  }

  const sizingStyle = pick(SIZING_STYLE as unknown as SizingProps[], style);
  const { boxSizing } = sizingStyle;

  if (boxSizing === '') {
    return null;
  }

  if (isIE && boxSizing === 'border-box') {
    sizingStyle.width =
      parseFloat(sizingStyle.width!) +
      parseFloat(sizingStyle.borderRightWidth!) +
      parseFloat(sizingStyle.borderLeftWidth!) +
      parseFloat(sizingStyle.paddingRight!) +
      parseFloat(sizingStyle.paddingLeft!) +
      'px';
  }

  const paddingSize =
    parseFloat(sizingStyle.paddingBottom!) +
    parseFloat(sizingStyle.paddingTop!);

  const borderSize =
    parseFloat(sizingStyle.borderBottomWidth!) +
    parseFloat(sizingStyle.borderTopWidth!);

  return {
    sizingStyle,
    paddingSize,
    borderSize,
  };
};
