import apiService from '#services/api';

import debug from '#debug';

import { FileResponse } from './responses/file.response';

class FilesService {
  private API_ROUTES = {
    uploadFile: 'files/upload',
  };

  public async uploadFile(file: File) {
    const formData = new FormData();

    formData.append('file', file, file.name);

    try {
      const request = await apiService.request.post(
        this.API_ROUTES.uploadFile,
        {
          body: formData,
        },
      );

      const result: FileResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ FileService ~ uploadFile ~ result:', result);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ FileService ~ uploadFile ~ err:', err);

      throw err;
    }
  }
}

export default FilesService;
