import { useState } from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import * as Styled from './styles';

import { useAppSelector } from '#hooks';

import { Button, InputField, MaskInputField, toastify } from '#ui-kit';

import { FormContainer, FormSection, RadioButtonFieldForm } from '#components';

import { extractDigits, phoneNumberValidationSchema } from '#utils';

import debug from '#services/debug.service';
import { UserService } from '#services/user';
import { UserRole } from '#services/user/responses/user.response';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный адрес электронной почты')
    .required('Поле обязательно для заполнения'),
  phoneNumber: phoneNumberValidationSchema.required(
    'Поле обязательно для заполнения',
  ),
});

const initialValues = {
  email: '',
  phoneNumber: '',
  role: UserRole.ADMIN,
};

const Form = () => {
  const userService = new UserService();

  const navigate = useNavigate();

  const { data: currentUserData } = useAppSelector(({ user }) => user);

  const [loading, setLoading] = useState(false);

  if (!currentUserData) {
    return <>Error</>;
  }

  const onSubmit = async ({ phoneNumber, ...rest }: typeof initialValues) => {
    setLoading(true);
    try {
      await userService.inviteUser({
        phoneNumber: extractDigits(phoneNumber),
        ...rest,
      });

      navigate('/users/admins');

      toastify.success('Приглашение успешно отправлено');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ Profile ~ Form ~ onSubmit ~ err:', err);

      toastify.error('Произошла ошибка при добавлении нового администратора');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled.FormikContainer>
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
        }}
      >
        <FormContainer
          leftSideActions={
            <Button
              className="user-form__submit"
              label="Создать"
              isLoading={loading}
            />
          }
        >
          <FormSection
            {...{
              label: 'Электронная почта',
              isRequired: true,
            }}
          >
            <InputField
              name="email"
              placeholder="Введите электронную почту"
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Номер телефона',
              isRequired: true,
            }}
          >
            <MaskInputField
              name="phoneNumber"
              placeholder="Введите номер телефона"
              mask="+7 (999) 999-99-99"
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Роль администратора',
            }}
          >
            <RadioButtonFieldForm
              title="Администратор"
              name="role"
              value={UserRole.ADMIN}
              disabled={loading}
            />
            {currentUserData?.role === UserRole.SUPERADMIN && (
              <RadioButtonFieldForm
                title="Супер администратор"
                subtitle="Супер администратор имеет право назначать других пользователей на роль супер администратора, предоставляя им полные права доступа к системе управления."
                name="role"
                value={UserRole.SUPERADMIN}
                disabled={loading}
              />
            )}
          </FormSection>
        </FormContainer>
      </Formik>
    </Styled.FormikContainer>
  );
};

export default Form;
