import { createAsyncThunk } from '@reduxjs/toolkit';

import AuthService from '#services/auth';
import { AuthDto } from '#services/auth/dto/auth.dto';
import debug from '#services/debug.service';
import { UserService } from '#services/user';
import { UserResponse } from '#services/user/responses/user.response';

const authService = new AuthService();
const userService = new UserService();

export const fetchUserWithCredentialsThunk = createAsyncThunk<
  UserResponse,
  AuthDto
>('user/fetchUserWithCredentialsThunk', async (data) => {
  try {
    const { user } = await authService.auth(data);

    return user;
  } catch (err) {
    debug.error('🧑🏻‍💻 ~ fetchUserWithCredentialsThunk ~ err:', err);

    throw err;
  }
});

export const fetchUserThunk = createAsyncThunk<UserResponse>(
  'user/fetchUserThunk',
  async () => {
    try {
      const result = await userService.getCurrentUser();

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ fetchUserThunk ~ err:', err);

      throw err;
    }
  },
);

export const clearUserData = createAsyncThunk(
  'user/clearUserData',
  async () => {
    try {
      await authService.logOut();
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ clearUserData ~ err:', err);

      throw err;
    }
  },
);
