import * as Styled from './styles';

import { FormContainerProps } from './types/FormContainer.types';

const Form: React.FC<FormContainerProps> = ({
  children,
  leftSideActions,
  rightSideActions,
}) => (
  <Styled.Container>
    <div>{children}</div>
    {(leftSideActions || rightSideActions) && (
      <Styled.ActionsContainer>
        <Styled.LeftSideActions>{leftSideActions}</Styled.LeftSideActions>
        <Styled.RightSideActions>{rightSideActions}</Styled.RightSideActions>
      </Styled.ActionsContainer>
    )}
  </Styled.Container>
);

export default Form;
