import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Separator = styled.hr`
  margin: ${ptr(44)} ${ptr(32)};
  border: 0;
  border-top: ${ptr(1)} solid ${colors.black._02};
`;

export const ButtonsContainer = styled.div`
  width: ${ptr(700)};
  display: flex;
  justify-content: space-between;
  margin-top: ${ptr(40)};
  margin: auto;

  .button {
    width: ${ptr(200)};
    height: ${ptr(50)};
    margin-left: ${ptr(20)};
  }
`;

export const RefundMoneyModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 10;
  padding-left: ${ptr(256)};
`;

export const RefundMoneyModal = styled.div`
  top: 25%;
  left: 50%;
  max-height: 60%;
  overflow: auto;
  scroll-behavior: auto;
  position: fixed;
  padding-bottom: ${ptr(12)};
  transform: translate(-50%, 0);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: ${ptr(8)};
`;
