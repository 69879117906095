import cn from 'classnames';

import * as Styled from './styles';

import { TypographySize, TypographyWeight } from '#ui-kit.types';

import { notAvailableValue } from '#utils';
import {
  StatusBadgeProps,
  StatusBadgeVariant,
} from './types/StatusBadge.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StatusBadge = <Q extends Record<any, string>>({
  data,
  value,
  className,
}: StatusBadgeProps<Q>) => {
  let currentStatus = data[value];

  if (!currentStatus) {
    currentStatus = {
      label: notAvailableValue(null),
      variant: StatusBadgeVariant.Disabled,
    };
  }

  const label = currentStatus.label;

  const variant = currentStatus.variant;

  return (
    <Styled.Container
      {...{ variant, className: cn('status-badge', className) }}
    >
      <Styled.Label
        {...{
          variant,
          size: TypographySize.s,
          weight: TypographyWeight.Light,
        }}
      >
        {label}
      </Styled.Label>
    </Styled.Container>
  );
};

export default StatusBadge;
