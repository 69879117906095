import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const ReceiptLine = styled.div`
  width: 100%;
  transition-duration: 0.2s;
  padding: 1% 4%;
  border-radius: ${ptr(8)};

  :hover {
    background-color: ${colors.primary._05};
    cursor: pointer;
  }
`;

export const ReceiptLineProduct = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition-duration: 0.2s;
  margin-bottom: ${ptr(10)};

  :hover {
    background-color: ${colors.primary._05};
    cursor: pointer;
  }
`;

export const ReceiptLineModifier = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  transition-duration: 0.2s;
  margin-left: 10%;
  margin-bottom: ${ptr(10)};

  :hover {
    background-color: ${colors.primary._05};
    cursor: pointer;
  }
`;