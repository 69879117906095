import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Logotype = styled.img.attrs({
  alt: 'Крошка картошка',
})`
  user-select: none;
  object-fit: contain;
  object-position: center;
`;

export const LogotypeContainer = styled.div``;

export const LogotypeLink = styled(Link)``;
