import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { formatPhoneNumber, notAvailableValue } from '#utils';

import { TableProps } from './types/Table.types';

const Component: React.FC<TableProps> = ({ loading, data: { data } }) => (
  <Table
    {...{
      loading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Наименование
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Адрес
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Номер телефона
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                ИНН
              </Typography>
            ),
          },
        },
      },
      rows: data.map(({ id, name, address, phoneNumber, inn }) => ({
        to: `/restaurants/${id}`,
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(name)}
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(address)}
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Right,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {formatPhoneNumber(notAvailableValue(phoneNumber))}
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Right,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(inn)}
              </Typography>
            ),
          },
        },
      })),
    }}
  />
);

export default Component;
