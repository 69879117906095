import { InputControlProps, InputDefaultProps } from '../../../types';

export enum InputTypes {
  Password = 'password',
  Text = 'text',
}

type InputFieldProps = Readonly<
  Partial<{
    type: InputTypes;
    maxLength: number;
    symbolCounter: boolean;
    clearable: boolean;
  }>
> &
  InputDefaultProps;

type InputProps = InputFieldProps & InputControlProps;

export type { InputProps, InputFieldProps };
