export enum SupportMessageType {
  BAD_SERVICE = 'BAD_SERVICE',
  DISLIKED_FOOD = 'DISLIKED_FOOD',
  SANITATION_CLEANLINESS = 'SANITATION_CLEANLINESS',
  APPRECIATION_STAFF = 'APPRECIATION_STAFF',
  MENU_UNAVAILABLE = 'MENU_UNAVAILABLE',
  OTHER = 'OTHER',
  DELIVERY_ISSUES = 'DELIVERY_ISSUES',
}

export type SupportMessageResponse = Readonly<{
  id: number;

  name: string;

  phoneNumber: string;

  email: string;

  message: string;

  type: SupportMessageType;

  restaurantId: number | null;

  fileIds: string[];

  createdAt: Date;
}>;
