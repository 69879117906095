import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ptr, borderParams } from '#styles.utils';

import { TableCellProps, TableCellAlign } from './types/TableCell.types';

export const TableCell = styled.td<TableCellProps>(
  ({
    width,
    align: textAlign = TableCellAlign.Center,
    padding,
    border: borderProperties,
    onClick,
  }) => {
    const border = borderProperties && borderParams(borderProperties);

    return {
      width: width && ptr(width),
      boxSizing: 'border-box',
      verticalAlign: 'middle',
      textAlign,
      padding,
      ...border,

      ':hover': {
        cursor: onClick && 'pointer',
      },
    };
  },
);

export const LinkedCell = styled(Link)`
  text-decoration: none;
`;
