import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag } from '#ui-kit.types';
import * as Styled from './styles/styles';
import { ProductCardProps } from './types/ProductCard.types';

const ProductCard: React.FC<ProductCardProps> = ({ data }) => {
  const { name, images } = data;
  return (
    <Styled.Container>
      <Styled.Image
        src={
          !!images?.length && images[0].url
            ? images[0].url
            : '/assets/no-photo.png'
        }
      />

      {name && (
        <div>
          <Typography size={TypographySize.m} tag={TypographyTag.span}>
            {name}
          </Typography>
        </div>
      )}
    </Styled.Container>
  );
};

export default ProductCard;
