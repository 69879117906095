import { useRestaurants } from '#hooks';

import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

// import Table from './containers/Table';

export default () => {
  const [data, { generalLoading }] = useRestaurants();

  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Настройки приложения',
          underlineVariant: HeadUnderlineVariant.Primary,
          button: {
            label: 'Добавить ресторан',
            to: '/restaurants/create',
          },
        }}
      />
      {/* <Table {...{ data, loading }} /> */}
    </>
  );
};
