import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { useDebounceEffect } from '#hooks';

import debug from '#services/debug.service';
import { UsersService } from '#services/users';
import { UsersResponse } from '../../services/user/responses/user.response';
import { UseUsersProps, UseUsersReturnProps } from './types/use-users.types';

const useUsers = ({
  role,
  search = '',
  status,
}: UseUsersProps): UseUsersReturnProps => {
  const usersService = new UsersService();

  const [data, setData] = useState<UsersResponse>();
  const [isLast, setIsLast] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [{ generalLoading, loading, preLoading }, setLoading] = useState({
    generalLoading: true,
    loading: false,
    preLoading: false,
  });

  const getUsers = async (thisFilterFunction = false) => {
    setLoading((old) => ({
      ...old,
      preLoading: thisFilterFunction,
      loading: !thisFilterFunction,
    }));
    try {
      const result = await usersService.getUsers({
        page: thisFilterFunction ? '1' : `${page}`,
        size: '20',
        role,
        search,
        status,
      });

      setData((prevState) => {
        if (prevState && !thisFilterFunction) {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }

        return result;
      });

      setIsLast(result.isLast);

      setPage((prevPageNumber) =>
        thisFilterFunction ? 2 : prevPageNumber + 1,
      );

      setLoading((old) => ({
        ...old,
        generalLoading: false,
        loading: false,
        preLoading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useUsers ~ getUsers ~ err:', err);
    }
  };

  useEffect(() => {
    if (!search && search !== '' && !status) getUsers();
  }, []);

  useEffect(() => {
    if (status || status === null) getUsers(true);
  }, [status]);

  useDebounceEffect(
    () => {
      if (search || search === '') getUsers(true);
    },
    [search],
    500,
  );

  useBottomScrollListener(() => {
    if (!generalLoading && !loading && !preLoading && !isLast) {
      getUsers();
    }
  });

  return [data, { generalLoading, loading, preLoading }];
};

export default useUsers;
