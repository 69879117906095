import apiService from '#services/api';

import { DocsResponse } from './responses/docs.response';

export class DocumentService {
  private readonly API_ROUTES = {
    licenseAgreement: () => 'documents/license-agreement',
    patchLicenseAgreement: () => 'documents/license-agreement',

    termsOfUse: () => 'documents/terms-of-use',
    patchTermsOfUse: () => 'documents/terms-of-use',

    privacyPolicy: () => 'documents/privacy-policy',
    patchPrivacyPolicy: () => 'documents/privacy-policy',
  };

  public async getLicenseAgreement() {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.licenseAgreement(),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: DocsResponse = await request.json();
      console.log(result, 'result from getLicenseAgreement');

      return result;
    } catch (err) {
      console.log(err, 'error from getLicenseAgreement');

      throw err;
    }
  }

  public async getPrivacyPolicy() {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.privacyPolicy(),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: DocsResponse = await request.json();

      return result;
    } catch (err) {
      console.log(err, 'error from getLicenseAgreement');

      throw err;
    }
  }

  public async patchLicenseAgreement(text: string) {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.patchLicenseAgreement(),
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name: 'License agreement',
            key: 'license-agreement',
            text: `${text}`,
          }),
        },
      );

      const result: DocsResponse = await request.json();

      return result;
    } catch (err) {
      console.log(err, 'error from patchPrivacyPolicy');

      throw err;
    }
  }

  public async patchPrivacyPolicy(text: string) {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.patchPrivacyPolicy(),
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name: 'Privacy policy',
            key: 'privacy-policy',
            text: `${text}`,
          }),
        },
      );

      const result: DocsResponse = await request.json();

      return result;
    } catch (err) {
      console.log(err, 'error from patchPrivacyPolicy');

      throw err;
    }
  }

  public async getTermsOfUse() {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.termsOfUse(),
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: DocsResponse = await request.json();

      return result;
    } catch (err) {
      console.log(err, 'error from getTermsOfUse');

      throw err;
    }
  }

  public async patchTermsOfUse(text: string) {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.patchTermsOfUse(),
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name: 'Terms of use',
            key: 'terms-of-use',
            text: `${text}`,
          }),
        },
      );

      const result: DocsResponse = await request.json();

      return result;
    } catch (err) {
      console.log(err, 'error from patchTermsOfUse');

      throw err;
    }
  }
}
