import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { useField } from 'formik';
import ReactInputMask from 'react-input-mask';

import * as Styled from '../input.styles';

import Error from '../../common/Error';
import { MaskInputFieldProps, MaskInputProps } from './types/MaskInput.types';

const MaskInput: React.FC<MaskInputProps> = React.memo(
  ({
    name,
    label = '',
    disabled = false,
    readOnly = false,
    className,
    placeholder = ' ',
    mask,
    value = '',
    maskPlaceholder = '_',
    alwaysShowMask = false,
    onChange: handleChange,
    beforeMaskedStateChange,
    parentClassName,
    error,
    touched,
  }) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (handleChange) handleChange(event.target.value);
    };

    return (
      <Styled.Container
        {...{
          className: cn(
            'input-container input-mask-container',
            parentClassName,
          ),
        }}
      >
        <Styled.Wrapper
          {...{ disabled, className: cn('input input-mask', className) }}
        >
          <ReactInputMask
            {...{
              value,
              mask,
              maskPlaceholder,
              alwaysShowMask,
              onChange,
              disabled,
              beforeMaskedStateChange,
              readOnly,
            }}
          >
            <Styled.Input
              {...{
                name,
                label,
                disabled,
                readOnly,
                onChange,
                placeholder,
              }}
            />
          </ReactInputMask>
          <Styled.Label>{label}</Styled.Label>
        </Styled.Wrapper>
        <Styled.BottomWrapper>
          <Error {...{ error, touched }} />
        </Styled.BottomWrapper>
      </Styled.Container>
    );
  },
);

const MaskInputField: React.FC<MaskInputFieldProps> = React.memo((props) => {
  const [{ value: initialValue }, { error, touched }, { setValue }] =
    useField<string>(props.name);

  const [value, onChange] = useState(initialValue);

  useEffect(() => {
    setValue(value, true);
  }, [value]);

  return (
    <MaskInput
      {...{
        ...props,
        value,
        onChange,
        error,
        touched,
      }}
    />
  );
});

export { MaskInputField };

export default MaskInput;
