import * as Styled from './styles';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import LayoutProps from './types/Layout.types';

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Styled.Container>
    <Sidebar />
    <Styled.Content>
      <Header />
      <Styled.Children>{children}</Styled.Children>
      <Footer />
    </Styled.Content>
  </Styled.Container>
);

export default Layout;
