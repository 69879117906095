import apiService from '#services/api';
import debug from '#services/debug.service';

import { GetSupportMessagesDto } from './dto/get-support-messages.dto';
import { SupportMessageResponse } from './responses/support-message.response';
import { SupportMessagesResponse } from './responses/support-messages.response';

export class SupportMessagesService {
  private readonly API_ROUTES = {
    support: 'support/',
  };

  public async getSupportMessages(searchParams: GetSupportMessagesDto) {
    try {
      const request = await apiService.request.get(this.API_ROUTES.support, {
        headers: {
          'content-type': 'application/json',
        },
        searchParams,
      });

      const result: SupportMessagesResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ SupportMessagesService ~ getSupportMessages ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error(
        '🧑🏻‍💻 ~ SupportMessagesService ~ getSupportMessages ~ err:',
        err,
      );

      throw err;
    }
  }

  public async getSupportMessage(messageId: string) {
    try {
      const request = await apiService.request.get(
        `${this.API_ROUTES.support}${messageId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: SupportMessageResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ SupportMessagesService ~ getSupportMessage ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error(
        '🧑🏻‍💻 ~ SupportMessagesService ~ getSupportMessage ~ err:',
        err,
      );

      throw err;
    }
  }
}
