import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import {
  formatPhoneNumber,
  formatSupportMessageType,
  notAvailableValue,
} from '#utils';

import { TableProps } from './types/Table.types';

const Component: React.FC<TableProps> = ({ data: { data }, loading }) => (
  <Table
    {...{
      loading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Имя отправителя
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Электронная почта
              </Typography>
            ),
          },
          2: {
            width: 200,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Номер телефона
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Тип обращения
              </Typography>
            ),
          },
          4: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Ресторан
              </Typography>
            ),
          },
        },
      },
      rows: data.map(
        ({ id, name, email, phoneNumber, restaurantId, type }) => ({
          to: `/support/${id}`,
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(name)}
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(email)}
                </Typography>
              ),
            },
            2: {
              width: 200,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {formatPhoneNumber(notAvailableValue(phoneNumber))}
                </Typography>
              ),
            },
            3: {
              width: 232,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {formatSupportMessageType(type)}
                </Typography>
              ),
            },
            4: {
              width: 232,
              align: TableCellAlign.Right,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.s}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                >
                  {notAvailableValue(restaurantId)}
                </Typography>
              ),
            },
          },
        }),
      ),
    }}
  />
);

export default Component;
