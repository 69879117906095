type Methods = 'info' | 'success' | 'error';

const DEBUG_PREFIX_INFO = '[DEBUG] INFO: ';
const DEBUG_PREFIX_SUCCESS = '[DEBUG] SUCCESS : ';
const DEBUG_PREFIX_ERROR = '[DEBUG] ERROR : ';

class Debug {
  /**
   * Set true when you are in development mode
   */
  private isDevelopment =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  /**
   * Set true when you are in production mode
   */
  isProduction = !this.isDevelopment;

  /**
   * Set names of methods that are available in development mode
   */
  private showIfDevelopment = ['info', 'success', 'error'];

  /**
   * Set names of methods that are available in production mode
   */
  private showIfProduction = ['error'];

  /**
   * Returns true if method allowed for current development or production mode.
   * If there is not development or production always return false.
   */
  public makeOrNot(method: Methods) {
    if (this.isDevelopment) {
      return this.showIfDevelopment.includes(method);
    }
    if (this.isProduction) {
      return this.showIfProduction.includes(method);
    }
    /**
     * Don't show logs by default
     */
    return false;
  }

  /**
   * Info log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info(message: string, ...data: any[]) {
    if (this.makeOrNot('info')) {
      console.log(
        `%c${new Date().toISOString()}`,
        'color: gray',
        DEBUG_PREFIX_INFO,
        message,
        data,
      );
    }
  }

  /**
   * Info log success
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public success(message: string, ...data: any[]) {
    if (this.makeOrNot('success')) {
      console.info(
        `%c${new Date().toISOString()} %c${DEBUG_PREFIX_SUCCESS}`,
        'color: gray',
        'color: green',
        message,
        data,
      );
    }
  }

  /**
   * Error log
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error(message: string, ...data: any[]) {
    if (this.makeOrNot('error')) {
      console.log(
        `%c${new Date().toISOString()} %c${DEBUG_PREFIX_ERROR}`,
        'color: white',
        'color: red',
        message,
        data,
      );
    }
  }
}
const debug = new Debug();
export default debug;
