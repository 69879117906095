import * as Styled from './styles';
import { colors } from '#styles.variables';

import { useAppDispatch, useAppSelector } from '#hooks';

import { Icon } from '#ui-kit';
import { IconNames, TypographySize, TypographyWeight } from '#ui-kit.types';

import { formatRoleString } from '#utils';

import { clearUserData } from '#store/user/thunks';

import { MenuItemProps, MenuItemType } from './types/Header.types';

const MenuItem: React.FC<MenuItemProps> = ({
  name,
  iconName,
  type = MenuItemType.Primary,
}) => (
  <Styled.MenuItem {...{ type }}>
    <Styled.MenuButton type="button">
      <Icon name={iconName} fill={colors.black._40} />
      <Styled.MenuItemName
        className="menu__name"
        color={colors.black._40}
        weight={TypographyWeight.Light}
      >
        {name}
      </Styled.MenuItemName>
    </Styled.MenuButton>
  </Styled.MenuItem>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Menu = () => (
  <Styled.MenuContainer className="header__menu">
    <Styled.HelperContainer />
    <Styled.Menu>
      <Styled.MenuList>
        <MenuItem
          name="Мой профиль"
          iconName={IconNames.user}
          onClick={() => {}}
        />
        <Styled.Triangle id="menu-triangle" />
      </Styled.MenuList>
    </Styled.Menu>
  </Styled.MenuContainer>
);

const CurrentUser = () => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(({ user }) => user);

  const logOut = () => {
    const isUserWantToLogout = confirm(
      'Вы действительно хотите выйти из системы?',
    );

    if (isUserWantToLogout) dispatch(clearUserData());
  };

  return (
    <Styled.UserSection>
      <Styled.User to="/profile">
        <Styled.Avatar src="/assets/images/logotypes/logo(3).png" />
        <Styled.Paragraph>
          <Styled.Username>{data?.name || 'N/A'}</Styled.Username>
          <Styled.UserRole
            color={colors.black._40}
            weight={TypographyWeight.Light}
            size={TypographySize.s}
          >
            {formatRoleString(data?.role)}
          </Styled.UserRole>
        </Styled.Paragraph>
        {/* <Menu /> */}
      </Styled.User>
      <Styled.LogoutButton onClick={logOut}>
        <Icon name={IconNames.logout} fill={colors.black._40} />
        <Styled.LogoutLabel
          size={TypographySize.s}
          weight={TypographyWeight.Light}
        >
          Выйти
        </Styled.LogoutLabel>
      </Styled.LogoutButton>
    </Styled.UserSection>
  );
};

const Header = () => (
  <Styled.Container>
    <div />
    <CurrentUser />
  </Styled.Container>
);

export default Header;
