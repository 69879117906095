import React from 'react';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { SpinnerProps, SpinnerSize } from './types/Spinner.types';

const size = {
  [SpinnerSize.Small]: {
    width: ptr(32),
    height: ptr(32),
  },
  [SpinnerSize.Medium]: {
    width: ptr(54),
    height: ptr(54),
  },
  [SpinnerSize.Large]: {
    width: ptr(120),
    height: ptr(120),
  },
};

const Spinner: React.FC<SpinnerProps> = ({
  size: spinnerSize = SpinnerSize.Small,
  color = colors.black._00,
}) => (
  <svg
    {...size[spinnerSize]}
    className="spinner"
    style={{
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(36 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(72 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.7s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(108 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(144 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(216 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(252 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.2s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(288 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.1s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(324 50 50)">
      <rect
        x="48"
        y="20"
        rx="1.68"
        ry="1.68"
        width="4"
        height="14"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
);

export default Spinner;
