import apiService from '#services/api';
import debug from '#services/debug.service';

import { GetDeliveryZonesDto } from './dto/get-delivery-zones.dto';
import { GetRestaurantsDto } from './dto/get-restaurants.dto';
import { UploadDeliveryZoneDto } from './dto/upload-delivery-zone.dto';
import { DeliveryZoneListResponse } from './responses/delivery-zone-list.response';
import { RestourantsResponse } from './responses/restaurants.response';

export class RestaurantsService {
  private readonly API_ROUTES = {
    restaurants: 'restaurants/',
    deliveryZone: 'restaurants/delivery-zones/',
  };

  public async getDeliveryZones(searchParams: GetDeliveryZonesDto) {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.deliveryZone,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams,
        },
      );

      const result: DeliveryZoneListResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ getDeliveryZones ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ getDeliveryZones ~ err:', err);

      throw err;
    }
  }

  public async uploadDeliveryZones(body: UploadDeliveryZoneDto) {
    try {
      await apiService.request.post(this.API_ROUTES.deliveryZone, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ uploadDeliveryZones ~ result: SUCCES',
      );
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ uploadDeliveryZones ~ err:', err);

      throw err;
    }
  }

  public async getRestaurants(searchParams: GetRestaurantsDto) {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.restaurants,
        {
          headers: {
            'content-type': 'application/json',
          },
          searchParams,
        },
      );

      const result: RestourantsResponse = await request.json();

      debug.success(
        '🧑🏻‍💻 ~ RestaurantsService ~ getRestaurants ~ result:',
        result,
      );

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ RestaurantsService ~ getRestaurants ~ err:', err);

      throw err;
    }
  }
}
