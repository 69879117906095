import { Formik } from 'formik';
import * as Yup from 'yup';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { useAppDispatch, useAppSelector } from '#hooks';

import { Button, InputField, Typography } from '#ui-kit';
import { InputTypes, TypographySize, TypographyTag } from '#ui-kit.types';

import { fetchUserWithCredentialsThunk } from '#store/user/thunks';
import { UserStatus } from '#store/user/types/user.types';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный адрес электронной почты')
    .required('Введите ваш адрес электронной почты'),
  password: Yup.string()
    .required('Введите пароль')
    .min(8, 'Пароль должен содержать не менее 8 символов'),
});

const Form = () => {
  const { status } = useAppSelector(({ user }) => user);

  const dispatch = useAppDispatch();

  const loading = status === UserStatus.Pending;

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = (data: typeof initialValues) => {
    dispatch(fetchUserWithCredentialsThunk(data));
  };

  return (
    <Styled.Container>
      <Styled.FormWrapper>
        <Styled.Title
          size={TypographySize.xl}
          color={colors.secondary._100}
          tag={TypographyTag.h1}
        >
          Авторизация
          <Styled.Underline src="/assets/images/underlines/underline(2).png" />
        </Styled.Title>
        <Formik
          {...{
            initialValues,
            validationSchema,
            onSubmit,
          }}
        >
          <Styled.Form>
            <InputField
              label="Электронная почта"
              name="email"
              placeholder="Введите ваш адрес эл. почты"
              disabled={loading}
            />
            <InputField
              label="Пароль"
              name="password"
              type={InputTypes.Password}
              placeholder="Введите ваш пароль"
              maxLength={16}
              disabled={loading}
            />
            <Button
              label="Войти"
              className="auth__login-button"
              isLoading={loading}
            />
          </Styled.Form>
        </Formik>
      </Styled.FormWrapper>
      <Styled.Footer>
        <Typography size={TypographySize.xs} color={colors.secondary._100}>
          Copyright © {new Date().getFullYear()}
        </Typography>
      </Styled.Footer>
    </Styled.Container>
  );
};

export default Form;
