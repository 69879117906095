import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

export const Container = styled.div`
  display: flex;
  align-items: baseline;

  :not(:last-child) {
    margin-bottom: ${ptr(16)};
  }
`;

export const Label = styled(Typography)``;

export const Subtitle = styled(Typography)`
  margin-top: ${ptr(12)};
  line-height: 130%;
`;

export const RadioButtonContainer = styled.div`
  flex-shrink: 1;
  position: relative;
  top: ${ptr(4)};
`;

export const TextContainer = styled.div`
  margin-top: ${ptr(4)};
  margin-left: ${ptr(12)};
`;
