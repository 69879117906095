// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notAvailableValue = (value: any): string => {
    if (Number.isNaN(value) || value === 'NaN') {
        return 'N/A';
    }

    if (typeof value === 'boolean' || typeof value === 'number') {
        return String(value);
    }

    return value ? String(value) : 'N/A';
};
