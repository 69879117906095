import React from 'react';

import { Routes, Route, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import * as Pages from '#pages';

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Container: React.FC = () => (
  <StyledContainer>
    <StyledWrapper>
      <Outlet />
    </StyledWrapper>
  </StyledContainer>
);

const PublicRoutes: React.FC = () => (
  <Routes>
    <Route element={<Container />}>
      <Route path="/new/:token" element={<Pages.Registration />} />
      <Route path="*" element={<Pages.AuthPage />} />
    </Route>
  </Routes>
);

export default PublicRoutes;
