import styled, { css } from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

export const TableWrapper = styled.div<{ preLoading: boolean }>`
  width: 100%;
  border-radius: ${ptr(4)};
  border: ${ptr(1)} solid ${colors.black._02};
  overflow-x: auto;
  overflow-y: hidden;
  background-color: ${colors.black._00};
  transition: ${transition.average};

  ${({ preLoading }) =>
    preLoading &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

export const Table = styled.table`
  display: table;
  width: 100%;

  tbody {
    > :last-child {
      border-color: transparent;
      border-bottom-width: 0 0 ${ptr(1)} 0;
    }
  }
`;
