import { IsObjectType } from './types';

export default class QueryBuilder<T> {
  private baseUrl: string;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private queryParams: { [key: string]: any };

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.queryParams = {};
  }

  setParam(
    name: IsObjectType<T> extends never ? string : keyof T,
    value: number | string | boolean,
  ): this {
    if (value === null || value === undefined) {
      return this;
    }
    if (this.queryParams[name as string]) {
      if (!Array.isArray(this.queryParams[name as string])) {
        this.queryParams[name as string] = [this.queryParams[name as string]];
      }
      this.queryParams[name as string].push(value);
      return this;
    }
    this.queryParams[name as string] = value;
    return this;
  }

  build(): string {
    const queryString = Object.entries(this.queryParams)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const query = value.map((val) => `${key}=${val}`);
          return query.join('&');
        }
        return `${key}=${value}`;
      })
      .join('&');
    return `${this.baseUrl}?${queryString}`;
  }
}
