import { useState } from 'react';


import { Typography } from '#ui-kit';
import {
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { OrderPricesResponseType } from '#services/orders/responses/order-prices.response';
import * as Styled from './style';
import { formatPrice } from 'utils/formatPrice';

type PriceProps = {
  data: OrderPricesResponseType & {
    isModifier: boolean;
  },
};

const LinePrice: React.FC<PriceProps> = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <Styled.Container onClick={toggleDetails}>
        {!showDetails && (
            <Typography
                size={TypographySize.m}
                tag={TypographyTag.span}
                weight={data.isModifier ? TypographyWeight.Light : TypographyWeight.Medium}
                style={{
                    textDecoration: 'underline',
                }}
            >
                {notAvailableValue(formatPrice(data?.discountedPrice))} руб.
            </Typography>
        )}
        {showDetails && (
            <Styled.Details>
                <Typography
                    tag={TypographyTag.span}
                >
                    <Typography
                        size={TypographySize.s}
                        tag={TypographyTag.p}
                        weight={TypographyWeight.Light}
                    >
                        Базовая цена:
                    </Typography>
                    <Typography
                        size={TypographySize.s}
                        tag={TypographyTag.p}
                        // weight={TypographyWeight.Light}
                    >
                        {notAvailableValue(formatPrice(data?.basePrice))} руб.
                    </Typography>
                </Typography>
                <Typography
                    tag={TypographyTag.span}
                >
                    <Typography
                        size={TypographySize.s}
                        tag={TypographyTag.p}
                        weight={TypographyWeight.Light}
                    >
                        С учетом скидок:
                    </Typography>
                    <Typography
                        size={TypographySize.s}
                        tag={TypographyTag.p}
                        // weight={TypographyWeight.Light}
                    >
                        {notAvailableValue(formatPrice(data?.discountedPrice))} руб.
                    </Typography>
                </Typography>
                {!data.isModifier && <>
                    <Typography
                        tag={TypographyTag.span}
                    >
                        <Typography
                            size={TypographySize.s}
                            tag={TypographyTag.p}
                            weight={TypographyWeight.Light}
                        >
                            С учетом модификаторов:
                        </Typography>
                        <Typography
                            size={TypographySize.s}
                            tag={TypographyTag.p}
                        >
                            {notAvailableValue(formatPrice(data?.fullBasePrice))} руб.
                        </Typography>
                    </Typography>
                    <Typography
                        tag={TypographyTag.span}
                    >
                        <Typography
                            size={TypographySize.s}
                            tag={TypographyTag.p}
                            weight={TypographyWeight.Light}
                        >
                            С учетом модификаторов и скидок:
                        </Typography>
                        <Typography
                            size={TypographySize.s}
                            tag={TypographyTag.p}
                        >
                            {notAvailableValue(formatPrice(data?.fullDiscountedPrice))} руб.
                        </Typography>
                    </Typography>
                </>
                }
            </Styled.Details>
        )}
      </Styled.Container>
    </>
  );
};

export default LinePrice;
