import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { SortingOrder } from '#utils.types';

import {
  TableCellAlign,
  TableCellProps,
} from '../TableCell/types/TableCell.types';

export const TableRowWrapper = styled.tr(() => ({
  width: '100%',
  overflow: 'hidden',
  backgroundColor: colors.black._02,
}));

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-right: ${ptr(4)} solid transparent;
  border-left: ${ptr(4)} solid transparent;
  border-bottom: ${ptr(5)} solid ${colors.black._10};
  margin: ${ptr(4)} 0;
  transition: ${transition.average};
`;

export const FigureGroup = styled.div<{ order?: SortingOrder }>(
  ({ order }) => ({
    marginLeft: ptr(8),

    '> :first-child': (() => {
      if (order === SortingOrder.ASC) {
        return {
          borderBottomColor: colors.black._40,
        };
      }
    })(),

    '> :last-child': (() => {
      if (order === SortingOrder.DESC) {
        return {
          transform: 'rotate(180deg)',
          borderBottomColor: colors.black._40,
        };
      }

      return {
        transform: 'rotate(180deg)',
      };
    })(),
  }),
);

export const TableCellWrapper = styled.div<
  Pick<TableCellProps, 'align' | 'onClick'>
>(({ align }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: (() => {
    if (align === TableCellAlign.Left) {
      return 'flex-start';
    }

    if (align === TableCellAlign.Right) {
      return 'flex-end';
    }

    return align;
  })(),
}));

export const SortArrowsIcon = styled.span`
  margin-left: ${ptr(8)};
`;
