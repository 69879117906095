import { configureStore } from '@reduxjs/toolkit';

import debug from '#debug';

import user from './user';

export const store = configureStore({
  reducer: {
    user,
  },
  devTools: !debug.isProduction,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
