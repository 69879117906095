import React from 'react';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

import TableCell from '../TableCell';
import { TableCellTag } from '../TableCell/types/TableCell.types';
import { TableEmptyRowProps } from './types/TableEmptyRow.types';

const TableEmptyRow: React.FC<TableEmptyRowProps> = ({ rows, header }) => {
  if (rows.length) {
    return <React.Fragment />;
  }

  const numberOfCells = Object.keys(header.cells).length;

  return (
    <TableCell tag={TableCellTag.tr}>
      <TableCell {...{ colSpan: numberOfCells, padding: ptr(24) }}>
        <Typography>Пусто</Typography>
      </TableCell>
    </TableCell>
  );
};

export default TableEmptyRow;
