import { Link } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

import { HeadUnderlineVariant } from './types/Head.types';

export const Container = styled.div`
  margin-bottom: ${ptr(24)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(Typography)`
  flex: 1;
  display: block;
  margin-top: ${ptr(4)};
  position: relative;
`;

export const TabContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  .custom-button {
    min-width: ${ptr(220)};
  }
`;

export const Button = styled.div`
  display: flex;
`;

export const ButtonLink = styled(Link)`
  display: flex;
  text-decoration: none;
`;

export const Underline = styled.img<{ underlineVariant: HeadUnderlineVariant }>(
  ({ underlineVariant }) => {
    const generalStyles: CSSObject = {
      position: 'absolute',
    };

    if (underlineVariant === HeadUnderlineVariant.Secondary)
      return {
        ...generalStyles,
        top: ptr(-26),
        width: ptr(48),
        height: ptr(48),
      };

    if (underlineVariant === HeadUnderlineVariant.Tertiary)
      return {
        ...generalStyles,
        left: 0,
        bottom: ptr(-10),
        width: ptr(240),
      };

    return {
      ...generalStyles,
      left: 0,
      bottom: ptr(-10),
      width: ptr(160),
    };
  },
);
