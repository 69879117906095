import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import debug from '#services/debug.service';
import { UserService } from '#services/user';
import { UserResponse } from '#services/user/responses/user.response';

import { UseUserReturnProps } from './types/use-user.types';

const useUser = (): UseUserReturnProps => {
  const userService = new UserService();

  const { userId } = useParams();

  const [data, setData] = useState<UserResponse>();

  const [{ generalLoading }, setLoading] = useState({
    generalLoading: true,
  });

  const getUser = async () => {
    if (userId) {
      try {
        const result = await userService.getUser(userId);

        setData(result);

        setLoading((old) => ({
          ...old,
          generalLoading: false,
        }));
      } catch (err) {
        debug.error('🧑🏻‍💻 ~ useUser ~ getUser ~ err:', err);
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return [data, { generalLoading }];
};

export default useUser;
