import { components } from 'react-select';
import styled from 'styled-components';

import { ptr } from '#styles.utils';

import { Typography } from '#ui-kit';

import { IndicatorOptionProps } from './types/IndicatorOption.types';

export const StyledIndicator = styled.figure<{ color: string }>(
  ({ color: backgroundColor }) => ({
    marginRight: ptr(10),
    minWidth: ptr(8),
    minHeight: ptr(8),
    backgroundColor,
    borderRadius: ptr(4),
  }),
);

export const StyledLabel = styled(Typography)`
  margin-top: ${ptr(4)};
`;

const IndicatorOption: React.FC<IndicatorOptionProps> = ({ ...props }) => (
  <components.Option {...props}>
    {props.data.color && <StyledIndicator color={props.data.color} />}
    <StyledLabel>{props.label}</StyledLabel>
  </components.Option>
);

export default IndicatorOption;
