import { ChangeEvent, useEffect, useState } from 'react';

import * as Styled from './styles';

import { Button, Typography } from '#ui-kit';

import { Head } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import { DocumentService } from '#services/Documents';
import { DocsResponse } from '#services/Documents/responses/docs.response';

export default () => {
  const [licenseAgreement, setLicenseAgreement] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [termsOfUse, setTermsOfUse] = useState('');
  const documentService = new DocumentService();

  const textareaStyle = {
    width: '80%',
    height: '150px',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '18px',
  };

  const getDocuments = async () => {
    try {
      const userAgreementDocs: DocsResponse =
        await documentService.getLicenseAgreement();
      setLicenseAgreement(userAgreementDocs.text);

      const privacyDocs: DocsResponse =
        await documentService.getPrivacyPolicy();
      setPrivacy(privacyDocs.text);

      const termsOfUseDocs: DocsResponse =
        await documentService.getTermsOfUse();
      setTermsOfUse(termsOfUseDocs.text);
    } catch (error) {
      console.log(error, 'error in getDocuments');
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const handleChangeLicenseAgreement = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setLicenseAgreement(event.target.value);
  };

  const handleSubmitLicenseAgreement = async () => {
    try {
      await documentService.patchLicenseAgreement(licenseAgreement);
    } catch (error) {
      console.log(error, 'error from handleSubmitLicenseAgreement');
    }
  };

  const handleChangePrivacyPolicy = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPrivacy(event.target.value);
  };

  const handleSubmitPrivacyPolicy = async () => {
    try {
      await documentService.patchPrivacyPolicy(privacy);
    } catch (error) {
      console.log(error, 'error from handleSubmitPrivacyPolicy');
    }
  };

  const handleChangeTermsOfUse = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTermsOfUse(event.target.value);
  };

  const handleSubmitTermsOfUse = async () => {
    try {
      await documentService.patchTermsOfUse(termsOfUse);
    } catch (error) {
      console.log(error, 'error from handleChangeTermsOfUse');
    }
  };

  return (
    <div>
      <Head
        {...{
          label: 'Документы',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Политика конфиденциальности</Typography>
        </Styled.TextContainer>
        <textarea
          id="privacy"
          style={textareaStyle}
          value={privacy}
          onChange={handleChangePrivacyPolicy}
        />
        <Styled.ButtonContainer>
          <Button
            label="Сохранить изменения"
            onClick={handleSubmitPrivacyPolicy}
          />
        </Styled.ButtonContainer>
      </Styled.Container>

      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Лицензионное соглашение</Typography>
        </Styled.TextContainer>
        <textarea
          id="userAgreement"
          style={textareaStyle}
          value={licenseAgreement}
          onChange={handleChangeLicenseAgreement}
        />
        <Styled.ButtonContainer>
          <Button
            label="Сохранить изменения"
            onClick={handleSubmitLicenseAgreement}
          />
        </Styled.ButtonContainer>
      </Styled.Container>

      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Пользовательское соглашение</Typography>
        </Styled.TextContainer>
        <textarea
          id="termsOfUse"
          style={textareaStyle}
          value={termsOfUse}
          onChange={handleChangeTermsOfUse}
        />
        <Styled.ButtonContainer>
          <Button
            label="Сохранить изменения"
            onClick={handleSubmitTermsOfUse}
          />
        </Styled.ButtonContainer>
      </Styled.Container>
    </div>
  );
};
