import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';

import { CounterProps } from './types/Counter.types';

export const StyledSymbolCounterContainer = styled.div({
  marginTop: ptr(10),
  marginLeft: 'auto',
});

const Counter = React.memo(function Counter({
  inputRef,
  maxLength,
  cleared,
}: CounterProps) {
  const [valueLength, setValueLength] = useState(0);

  const listner = () => {
    if (inputRef?.current) setValueLength(inputRef.current.value.length);
  };

  useEffect(() => {
    setValueLength(inputRef.current?.value.length || 0);
    if (inputRef?.current) inputRef.current.addEventListener('input', listner);

    return () => {
      if (inputRef?.current)
        inputRef.current.removeEventListener('input', listner);
    };
  }, [inputRef?.current]);

  useEffect(() => {
    if (cleared) {
      setValueLength(0);
    }
  }, [cleared]);

  return (
    <StyledSymbolCounterContainer className="input-counter">
      <Typography
        className="input-counter__value"
        tag={TypographyTag.span}
        weight={TypographyWeight.Light}
        size={TypographySize.xs}
        color={colors.black._20}
      >
        {valueLength}
        {maxLength ? `/${maxLength}` : ''}
      </Typography>
    </StyledSymbolCounterContainer>
  );
});

export default Counter;
