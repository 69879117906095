import { GroupBase, StylesConfig } from 'react-select';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import {
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';

import * as getDefaultStyles from '../styles';

export const Container = styled.div``;

export const BottomWrapper = styled.div(getDefaultStyles.bottomWrapperStyles);

const getStyles = (): StylesConfig<unknown, false, GroupBase<unknown>> => ({
  placeholder: (styles) => ({
    ...styles,
    fontWeight: TypographyWeight.Light,
    fontSize: ptr(16),
    color: colors.black._10,
    fontFamily: TypographyFamily.FactorA,
    letterSpacing: '0!important',
    marginLeft: 0,
  }),
  input: (styles) => ({
    ...styles,
    fontSize: ptr(16),
    lineHeight: ptr(20),
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: ptr(TypographySize.m),
    color: colors.secondary._100,
    fontWeight: TypographyWeight.Medium,
    fontFamily: TypographyFamily.FactorA,
  }),
  clearIndicator: (styles, { isFocused }) => ({
    ...styles,
    cursor: 'pointer',
    transition: transition.average,
    pointerEvents: isFocused ? 'all' : 'none',
    opacity: isFocused ? 1 : 0,
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    background: colors.black._00,
    transition: transition.average,
    border: `${ptr(1)} solid ${colors.black._10}`,
    borderRadius: ptr(4),
    boxShadow: 'none',
    minHeight: ptr(60),
    opacity: isDisabled ? 0.4 : 1,
    ':hover': {
      borderColor: colors.black._10,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: ptr(16),
    paddingTop: ptr(4),
    paddingBottom: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    height: ptr(32),
    alignSelf: 'center',
    backgroundColor: colors.black._05,
  }),
  menu: (styles) => ({
    ...styles,
    padding: 0,
    marginTop: ptr(12),
    background: colors.black._00,
    border: `${ptr(1)} solid ${colors.black._10}`,
    boxShadow: 'none',
    borderRadius: ptr(4),
    overflow: 'hidden',
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: ptr(60 * 5),
    '::-webkit-scrollbar': {
      width: ptr(4),
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.black._20,
      borderRadius: ptr(8),
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    padding: 0,
    height: ptr(60),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  option: (styles) => ({
    ...styles,
    cursor: 'pointer',
    borderBottom: `${ptr(1)} solid ${colors.black._10}`,
    padding: 0,
    height: ptr(60),
    color: colors.secondary._100,
    fontWeight: TypographyWeight.Medium,
    fontFamily: TypographyFamily.FactorA,
    transition: transition.average,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: ptr(16),
    ':hover': {
      backgroundColor: colors.black._02,
    },
    ':last-child': {
      borderBottom: 'none',
    },
  }),
});

export default getStyles;
