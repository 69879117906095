import { notAvailableValue } from '#utils';

import { UserRole } from '#services/user/responses/user.response';

export const formatRoleString = (role: UserRole | undefined) => {
  switch (role) {
    case UserRole.SUPERADMIN:
      return 'Супер администратор';

    case UserRole.ADMIN:
      return 'Администратор';

    case UserRole.USER:
      return 'Пользователь';

    default:
      notAvailableValue(role);
  }
};
