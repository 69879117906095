import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';
import { TypographyFamily, TypographySize } from '#ui-kit.types';

export const Container = styled.div`
  margin-bottom: ${ptr(24)};
  width: 80%;
`;

export const TextContainer = styled.div`
  margin-bottom: ${ptr(18)};
`;

export const ButtonContainer = styled.div`
  width: 45%;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: ${ptr(10)};
  margin-bottom: ${ptr(18)};
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${ptr(18)};
  width: 80%;
`;

export const FilterContainer = styled.div`
  width: ${ptr(200)};
  display: flex;
  border-radius: ${ptr(20)};
  border: ${ptr(1)} solid ${colors.black._02};
  height: ${ptr(40)};
  margin-bottom: ${ptr(18)};
  background: ${colors.black._02};
`;

export const FilterItem = styled.div`
  width: ${ptr(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background ${transition.average} ease-in-out;
  border-radius: ${ptr(4)};

  .filter_item_text {
    font-size: ${TypographySize.m};
    font-family: ${TypographyFamily.FactorA};
  }
  ,
  .filter_item_text:hover {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${ptr(48)};
  width: 80%;
`;
