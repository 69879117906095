import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const OrderStatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  .status-badge {
    min-width: ${ptr(140)};
  }
`;

export const Separator = styled.hr`
  margin: ${ptr(44)} ${ptr(32)};
  border: 0;
  border-top: ${ptr(1)} solid ${colors.black._02};
`;
