import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Container = styled.div`
  display: flex;
  margin-bottom: ${ptr(12)};
`;

export const SearchContainer = styled.div`
  flex: 2;

  .input {
    background-color: ${colors.black._02};

    input {
      border-color: transparent;
    }
  }
`;
export const FilterControls = styled.div`
  flex: 1;

  .select-container {
    padding-left: ${ptr(24)};
  }

  .filter__control {
    background-color: ${colors.black._02};
    border-color: transparent;

    :hover {
      border-color: transparent;
    }
  }

  .filter__menu {
    border-color: ${colors.black._05};
    background-color: ${colors.black._00};

    .filter__option {
      border-color: ${colors.black._05};
    }
  }
`;
