import { CSSObject } from 'styled-components';

export enum TypographyFamily {
  FactorA = 'Factor A',
}

export enum TypographySize {
  xs = 12,
  s = 14,
  m = 16,
  l = 20,
  xl = 24,
  xxl = 32,
  xxxl = 64,
}

export enum TypographyTag {
  p = 'p',
  span = 'span',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
}

export enum TypographyWeight {
  Thin = 300,
  Light = 400,
  Medium = 500,
  Bold = 600,
}

export type TypographyProps = Readonly<
  Partial<{
    className: string;
    size: TypographySize;
    children: React.ReactNode;
    tag: TypographyTag;
    weight: TypographyWeight;
    style: CSSObject;
    color: string;
    fontFamily: TypographyFamily;
    dangerouslySetInnerHTML: { __html: string };
  }>
>;
