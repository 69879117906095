import styled from 'styled-components';

import { ptr } from '#styles.utils';

export const Container = styled.div`
  margin-bottom: ${ptr(50)};
`;

export const TextContainer = styled.div`
  margin-bottom: ${ptr(18)};
`;

export const ButtonContainer = styled.div`
  width: ${ptr(400)};
`;
