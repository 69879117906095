export enum SpinnerSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type SpinnerProps = Readonly<
  Partial<{
    color: string;
    size: SpinnerSize;
  }>
>;
