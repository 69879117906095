import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import debug from '#services/debug.service';
import { SupportMessagesService } from '#services/support';
import { SupportMessagesResponse } from '#services/support/responses/support-messages.response';

import { UseSupportMessagesReturnProps } from './types/use-support-messages.types';

const useSupportMessages = (): UseSupportMessagesReturnProps => {
  const supportMessagesService = new SupportMessagesService();

  const [data, setData] = useState<SupportMessagesResponse>();
  const [isLast, setIsLast] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [{ generalLoading, loading }, setLoading] = useState({
    generalLoading: true,
    loading: false,
  });

  const getSupportMessages = async () => {
    setLoading((old) => ({
      ...old,
      loading: true,
    }));
    try {
      const result = await supportMessagesService.getSupportMessages({
        page: `${page}`,
        size: '20',
      });

      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }

        return result;
      });

      setIsLast(result.isLast);

      setPage((prevPageNumber) => prevPageNumber + 1);

      setLoading((old) => ({
        ...old,
        generalLoading: false,
        loading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useSupportMessages ~ getSupportMessages ~ err:', err);
    }
  };

  useEffect(() => {
    getSupportMessages();
  }, []);

  useBottomScrollListener(() => {
    if (!generalLoading && !loading && !isLast) {
      getSupportMessages();
    }
  });

  return [data, { generalLoading, loading }];
};

export default useSupportMessages;
