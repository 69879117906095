import { createSlice } from '@reduxjs/toolkit';

import {
  clearUserData,
  fetchUserThunk,
  fetchUserWithCredentialsThunk,
} from './thunks';
import UserState, { UserStatus } from './types/user.types';

const initialState = {
  status: UserStatus.Idle,
  data: undefined,
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    restoreUserData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserWithCredentialsThunk.pending, (state) => {
        state.status = UserStatus.Pending;
      })
      .addCase(
        fetchUserWithCredentialsThunk.fulfilled,
        (state, { payload }) => {
          state.status = UserStatus.Successed;
          state.data = payload;
        },
      )
      .addCase(fetchUserWithCredentialsThunk.rejected, (state) => {
        state.status = UserStatus.Failed;
      })
      .addCase(fetchUserThunk.pending, (state) => {
        state.status = UserStatus.Loading;
      })
      .addCase(fetchUserThunk.fulfilled, (state, { payload }) => {
        state.status = UserStatus.Successed;
        state.data = payload;
      })
      .addCase(fetchUserThunk.rejected, (state) => {
        state.status = UserStatus.Failed;
      })
      .addCase(clearUserData.pending, (state) => {
        state.status = UserStatus.Loading;
      })
      .addCase(clearUserData.fulfilled, (state) => {
        state.status = UserStatus.Idle;
        state.data = undefined;
      })
      .addCase(clearUserData.rejected, (state) => {
        state.status = UserStatus.Failed;
      });
  },
});

export const userSliceActions = userSlice.actions;
export default userSlice.reducer;
