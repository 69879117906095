import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import debug from '#services/debug.service';
import { SupportMessagesService } from '#services/support';
import { SupportMessageResponse } from '#services/support/responses/support-message.response';

import { UseSupportMessageReturnProps } from './types/use-support-message.types';

const useSupportMessage = (): UseSupportMessageReturnProps => {
  const supportMessagesService = new SupportMessagesService();

  const { messageId } = useParams();

  const [data, setData] = useState<SupportMessageResponse>();

  const [{ generalLoading }, setLoading] = useState({
    generalLoading: true,
  });

  const getSupportMessage = async () => {
    if (messageId) {
      try {
        const result = await supportMessagesService.getSupportMessage(
          messageId,
        );

        setData(result);

        setLoading((old) => ({
          ...old,
          generalLoading: false,
        }));
      } catch (err) {
        debug.error('🧑🏻‍💻 ~ useSupportMessage ~ getSupportMessage ~ err:', err);
      }
    }
  };

  useEffect(() => {
    getSupportMessage();
  }, []);

  return [data, { generalLoading }];
};

export default useSupportMessage;
