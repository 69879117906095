import React, { useEffect, useState } from 'react';

import { InputButton } from '../../Inputs/input.styles';

import { Icon } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

import { ClearButtonProps } from './types/ClearButton.types';

const ClearButton = React.memo(function ClearButton({
  inputRef,
  onClear,
}: ClearButtonProps) {
  const [isEmpty, setIsEmpty] = useState(true);

  const listner = () => {
    if (inputRef?.current) setIsEmpty(!inputRef.current.value.length);
  };

  const handleReset = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    onClear({ target: { value: '' } });
  };

  useEffect(() => {
    setIsEmpty(!inputRef.current?.value);

    if (inputRef?.current) inputRef.current.addEventListener('input', listner);

    return () => {
      if (inputRef?.current)
        inputRef.current?.removeEventListener('input', listner);
    };
  }, [inputRef?.current?.value]);

  if (isEmpty) {
    return <React.Fragment />;
  }

  return (
    <InputButton className="input-reset" onClick={handleReset}>
      <Icon name={IconNames.cross} width={24} height={24} stroke="#000" />
    </InputButton>
  );
});

export default ClearButton;
