import { ClearIndicatorProps, components } from 'react-select';

import { Icon } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <Icon name={IconNames.cross} />
  </components.ClearIndicator>
);

export default ClearIndicator;
