import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';

import { ErrorProps } from './types/Error.types';

export const StyledErrorLabel = styled(Typography)``;

export const StyledError = styled.div`
  margin-top: ${ptr(10)};
`;

const Error: React.FC<ErrorProps> = ({ error, touched }) => (
  <StyledError>
    {touched && error && (
      <StyledErrorLabel
        size={TypographySize.s}
        color={colors.error._100}
        weight={TypographyWeight.Light}
        tag={TypographyTag.span}
      >
        {error}
      </StyledErrorLabel>
    )}
  </StyledError>
);

export default Error;
