import styled, { css } from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import {
  IconNames,
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';

import * as getStyles from '../styles';

export const inputStyles = ({
  label,
  icon,
}: {
  label: string;
  icon?: IconNames;
}) => {
  const isLabelExist = !!label;

  return css({
    display: 'block',
    padding: `${ptr(22)} ${ptr(16)} ${ptr(18)} ${icon ? ptr(52) : ptr(16)}`,
    border: `${ptr(1)} solid ${colors.black._10}`,
    borderRadius: 'inherit',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    background: 'transparent',
    fontSize: ptr(TypographySize.m),
    color: colors.secondary._100,
    fontWeight: TypographyWeight.Medium,
    transition: `border-color ${transition.average}`,
    resize: 'none',
    fontFamily: TypographyFamily.FactorA,

    '&[type="password"]': {
      letterSpacing: ptr(2),
    },

    '&::placeholder': {
      opacity: isLabelExist ? 0 : 1,
      fontWeight: TypographyWeight.Light,
      fontSize: ptr(16),
      color: colors.black._10,
      fontFamily: TypographyFamily.FactorA,
      letterSpacing: '0!important',
      top: 0,
      position: 'relative',
    },

    '&:focus': {
      borderColor: colors.black._20,

      '& ~ .input-reset': {
        opacity: 1,
      },
    },

    '&:focus, &:not(:placeholder-shown)': {
      padding: isLabelExist
        ? `${ptr(30)} ${ptr(16)} ${ptr(10)}`
        : `${ptr(22)} ${ptr(16)} ${ptr(18)} ${icon ? ptr(52) : ptr(16)}`,

      '&::placeholder': {
        opacity: 1,
      },
    },

    '&:focus + label, &:not(:placeholder-shown) + label': {
      top: ptr(12),
      fontSize: ptr(TypographySize.s),
      color: colors.black._40,
    },
  });
};

export const IconContainer = styled.div`
  position: absolute;
  left: ${ptr(16)};
  top: ${ptr(18)};
`;

export const InputButton = styled.button.attrs({
  type: 'button',
  tabIndex: -1,
})({
  position: 'absolute',
  backgroundColor: 'transparent',
  border: 0,
  outline: 0,
  right: ptr(9),
  top: ptr(18),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: transition.average,
  padding: 0,

  '&.input-reset': {
    opacity: 0,
  },
});

export const Input = styled.input<{
  label: string;
  icon?: IconNames;
}>(({ label, icon }) => inputStyles({ label, icon }));

export const Container = styled.div``;

export const Wrapper = styled.div<{ disabled: boolean }>(
  getStyles.wrapperStyles,
);

export const Label = styled.label(getStyles.labelStyles);

export const BottomWrapper = styled.div(getStyles.bottomWrapperStyles);
