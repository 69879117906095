import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import debug from '#services/debug.service';
import { RestaurantsService } from '#services/restaurants';
import { DeliveryZoneListResponse } from '#services/restaurants/responses/delivery-zone-list.response';

import { UseDeliveryZonesReturnProps } from './types/use-delivery-zones.types';

const useDeliveryZones = (): UseDeliveryZonesReturnProps => {
  const restaurantsService = new RestaurantsService();

  const [data, setData] = useState<DeliveryZoneListResponse>();
  const [isLast, setIsLast] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [{ generalLoading, loading }, setLoading] = useState({
    generalLoading: true,
    loading: false,
  });

  const getDeliveryZones = async (updateDeliveryZones = false) => {
    if (updateDeliveryZones) {
      const result = await restaurantsService.getDeliveryZones({
        page: '1',
        size: '20',
      });
      return result;
    }
    setLoading((old) => ({
      ...old,
      loading: false,
    }));
    try {
      const result = await restaurantsService.getDeliveryZones({
        page: `${page}`,
        size: '20',
      });

      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }

        return result;
      });

      setIsLast(result.isLast);

      setPage((prevPageNumber) => prevPageNumber + 1);

      setLoading((old) => ({
        ...old,
        generalLoading: false,
        loading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useUsers ~ getUsers ~ err:', err);
    }
  };

  useEffect(() => {
    getDeliveryZones();
  }, []);

  useBottomScrollListener(() => {
    if (!generalLoading && !loading && !isLast) {
      getDeliveryZones();
    }
  });

  return [data, getDeliveryZones, setData, { generalLoading, loading }];
};

export default useDeliveryZones;
