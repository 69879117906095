import React from 'react';
import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';
import { Icon } from '#ui-kit';
import { IconNames, TypographyFamily, TypographySize } from '#ui-kit.types';
import { Tooltip } from 'react-tooltip';

const OuterInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${ptr(4)};
  max-width: ${ptr(160)};
`;

const InnerInputWrapper = styled.div<{ $error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: solid ${ptr(1)}
    ${({ $error }) => ($error ? colors.error._100 : colors.secondary._100)};
`;

const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-family: ${TypographyFamily.FactorA};
  font-size: ${ptr(TypographySize.m)};
  line-height: ${ptr(TypographySize.l)};
  caret-color: ${colors.primary._100};
`;

const ErrorText = styled.span`
  font-family: ${TypographyFamily.FactorA};
  font-size: ${ptr(TypographySize.s)};
  line-height: ${ptr(TypographySize.l)};
  color: ${colors.error._100};
`;

const tooltipStyle: React.CSSProperties = {
  borderRadius: ptr(4),
  maxWidth: ptr(180),
  padding: ptr(12),
  fontFamily: TypographyFamily.FactorA,
  fontSize: ptr(TypographySize.xs),
  lineHeight: ptr(TypographySize.l),
  backgroundColor: colors.black._05,
  color: colors.secondary._100,
};

const TOOLTIP_ID = 'priority-tooltip';
const HINT_ICON_SIZE = 20;

type PriorityInputProps = Readonly<
  Partial<{
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    hint: string;
    error: string;
  }>
>;

const PriorityInput: React.FC<PriorityInputProps> = (props) => {
  const { value, onChange, placeholder, hint, error } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <OuterInputWrapper>
      <InnerInputWrapper $error={!!error}>
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {hint && (
          <a
            data-tooltip-id={TOOLTIP_ID}
            data-tooltip-content={hint}
            data-tooltip-place="bottom-start"
          >
            <Icon
              name={IconNames.info}
              width={HINT_ICON_SIZE}
              height={HINT_ICON_SIZE}
              fill={colors.black._10}
            />
            <Tooltip id={TOOLTIP_ID} style={tooltipStyle} />
          </a>
        )}
      </InnerInputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </OuterInputWrapper>
  );
};

export default PriorityInput;
