export enum StatusBadgeVariant {
  Active = 'active',
  Disabled = 'disabled',
  Warning = 'warning',
  Blocked = 'blocked',
  Processing = 'processing',
}

type StatusBadgeDataProps = Readonly<{
  label: string;
  variant: StatusBadgeVariant;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface StatusBadgeProps<Q extends Record<any, string>> {
  className?: string;
  value: keyof Q;
  data: Record<keyof Q, StatusBadgeDataProps>;
}
