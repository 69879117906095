import React from 'react';

import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import {
  TypographyTag,
  TypographySize,
  TypographyWeight,
  TypographyProps,
  TypographyFamily,
} from './types/Typography.types';

const Typography = styled(
  ({ tag = TypographyTag.p, ...props }: TypographyProps) =>
    React.createElement(tag, { ...props }, props.children),
)(
  ({
    size = TypographySize.m,
    weight: fontWeight = TypographyWeight.Medium,
    color = colors.secondary._100,
    fontFamily = TypographyFamily.FactorA,
  }) => ({
    fontWeight,
    fontFamily,
    fontSize: ptr(size),
    lineHeight: '110%',
    color,
  }),
);

export default Typography;
