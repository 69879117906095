import { NoticeProps, components } from 'react-select';

import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographyTag, TypographyWeight } from '#ui-kit.types';

const NoOptionsMessage = (props: NoticeProps) => (
  <components.NoOptionsMessage {...props}>
    <Typography
      {...{
        tag: TypographyTag.p,
        color: colors.black._20,
        weight: TypographyWeight.Light,
      }}
    >
      {props.children}
    </Typography>
  </components.NoOptionsMessage>
);

export default NoOptionsMessage;
