import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { useField } from 'formik';
import ReactSelect, {
  GroupBase,
  OptionProps as OptionType,
} from 'react-select';

import getStyles, * as Styled from '../select.styles';

import { findSelectedOption } from '#utils';

import ClearIndicator from '../../common/ClearIndicator';
import DropdownIndicator from '../../common/DropdownIndicator';
import Error from '../../common/Error';
import NoOptionsMessage from '../../common/NoOptionsMessage';
import Option from '../../common/Option';
import { OptionProps } from '../../types';
import {
  SingleSelectFieldProps,
  SingleSelectProps,
} from './types/SingleSelect.types';

const SingleSelect: React.FC<SingleSelectProps> = ({
  name,
  className,
  options,
  placeholder = '',
  classNamePrefix,
  disabled: isDisabled = false,
  clearable: isClearable = false,
  searchable: isSearchable = false,
  closeMenuOnSelect = true,
  noOptionsMessage = 'Нет доступных опций',
  error,
  touched,
  parentClassName,
  readOnly,
  value,
  onChange = () => {},
  components = {
    Option,
  },
}) => (
  <Styled.Container {...{ className: cn('select-container', parentClassName) }}>
    <ReactSelect
      {...{
        name,
        className,
        placeholder,
        classNamePrefix,
        readOnly,
        isDisabled,
        isClearable,
        isSearchable,
        value,
        onChange: (res) => onChange(res as OptionProps<string>),
        styles: getStyles(),
        closeMenuOnSelect,
        options,
        menuIsOpen: readOnly ? false : undefined,
        noOptionsMessage: () => noOptionsMessage,
        components: {
          DropdownIndicator,
          NoOptionsMessage,
          ClearIndicator,
          ...(components as OptionType<unknown, false, GroupBase<unknown>>),
        },
      }}
    />
    <Styled.BottomWrapper>
      <Error {...{ error, touched }} />
    </Styled.BottomWrapper>
  </Styled.Container>
);

const SingleSelectField: React.FC<SingleSelectFieldProps> = React.memo(
  (props) => {
    const [{ value: initialValue }, { error, touched }, { setValue }] =
      useField<string | null>(props.name);

    const [value, onChange] = useState(initialValue);

    useEffect(() => {
      setValue(value, true);
    }, [value]);

    return (
      <SingleSelect
        {...{
          ...props,
          value: findSelectedOption(value, props.options),
          onChange: (output) => {
            if (output?.value) onChange(output.value);
            else onChange(null);
          },
          error,
          touched,
        }}
      />
    );
  },
);

export { SingleSelectField };

export default SingleSelect;
