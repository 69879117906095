import { useState } from 'react';


import { Typography } from '#ui-kit';
import {
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { OrderItemResponseType } from '#services/orders/responses/order-item.response';
import * as Styled from './style';

type LineQuantityProps = {
  data: Pick<OrderItemResponseType, 'quantity' | 'refundedQuantity'> & {
    isModifier: boolean;
  },
};

const LineQuantity: React.FC<LineQuantityProps> = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Styled.Container onClick={toggleDetails}>
      {!showDetails && (
          <Typography
              size={TypographySize.m}
              tag={TypographyTag.span}
              weight={data.isModifier ? TypographyWeight.Light : TypographyWeight.Medium}
              style={{
                  textDecoration: 'underline',
              }}
          >
              {'x' + notAvailableValue(data?.quantity)}
          </Typography>
      )}
      {showDetails && (
          <Styled.Details>
              <Typography
                  tag={TypographyTag.span}
              >
                  <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.p}
                      weight={TypographyWeight.Light}
                  >
                      Количество:
                  </Typography>
                  <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.p}
                  >
                      {notAvailableValue(data?.quantity)}
                  </Typography>
              </Typography>
              <Typography
                  tag={TypographyTag.span}
              >
                  <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.p}
                      weight={TypographyWeight.Light}
                  >
                    Возвращенное количество:
                  </Typography>
                  <Typography
                      size={TypographySize.s}
                      tag={TypographyTag.p}
                      // weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(data?.refundedQuantity)}
                  </Typography>
              </Typography>
          </Styled.Details>
      )}
    </Styled.Container>
  );
};

export default LineQuantity;
