import React from 'react';

import * as Styled from './styles';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { TableCellTag } from '#ui-kit.types';

import TableCell from '../TableCell';
import { TableHeaderProps } from './types/TableHeader.types';

const TableHeader: React.FC<TableHeaderProps> = ({ cells = {}, border }) => (
  <Styled.TableRowWrapper>
    {Object.entries(cells).map(
      ([columnId, { align, order, children, ...rest }]) => (
        <TableCell
          {...{
            ...rest,
            align,
            key: columnId,
            tag: TableCellTag.th,
            border: border || {
              width: 1,
              style: 'solid',
              color: colors.black._02,
              sides: ['bottom'],
            },
            padding: `${ptr(16)} ${ptr(24)}`,
          }}
        >
          {order ? (
            <Styled.TableCellWrapper {...{ align }}>
              {children}
              <Styled.FigureGroup {...{ order }}>
                <Styled.Triangle />
                <Styled.Triangle />
              </Styled.FigureGroup>
            </Styled.TableCellWrapper>
          ) : (
            children
          )}
        </TableCell>
      ),
    )}
  </Styled.TableRowWrapper>
);

export default TableHeader;
