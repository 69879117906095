import { useEffect, useState } from 'react';
// import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { colors } from '#styles.variables';
import { Controllers, Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import debug from '#services/debug.service';
import { OrdersService } from '#services/orders';
import { OrderInternalStatusEnum } from '#services/orders/enums/order-internal-status.enum';
import { OrdersType } from '#services/orders/responses/orders.response';
import Form from './containers/Table';
import * as Styled from './style';

export default () => {
  const ordersService = new OrdersService();

  const [showOrders, setShowOrders] = useState<'ACTIVE' | 'COMPLETED'>(
    'ACTIVE',
  );
  const [orders, setOrders] = useState<OrdersType>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  // const [isLast, setIsLast] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [totalPages, setTotalPages] = useState<number>(0);

  const getOrders = async (filterFunction = false, showMore = false) => {
    try {
      if (showMore) {
        setLoading(true);
      }

      const internalStatus =
        showOrders === 'ACTIVE'
          ? [OrderInternalStatusEnum.IN_PROGRESS, OrderInternalStatusEnum.NEW]
          : [
              OrderInternalStatusEnum.COMPLETED,
              OrderInternalStatusEnum.FAILED,
              OrderInternalStatusEnum.CANCELED,
            ];

      const params = {
        page: page,
        size: size,
        createdAtSort: 'desc',
        internalStatus,
      };

      const ordersParams = !!searchValue.length
        ? { ...params, orderNumber: searchValue }
        : params;
      const response = await ordersService.getOrders(ordersParams);
      setOrders((old: OrdersType) => {
        if (page === 1 || filterFunction) {
          return response.data;
        } else {
          return [...old, ...response.data];
        }
      });
      // setPage((prevPageNumber) => prevPageNumber + 1);
      // setIsLast(response.isLast);
      setTotalPages(response.total);
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getOrders ~ err:', e);
    } finally {
      if (showMore) {
        setLoading(false);
      }
    }
  };

  const handleShowOrders = (value: 'ACTIVE' | 'COMPLETED') => {
    // setPage(1);
    setShowOrders(value);
  };

  const onPressShowOrdersBtn = (value: 'ACTIVE' | 'COMPLETED') => {
    handleShowOrders(value);
    // getOrders();
  };

  useEffect(() => {
    getOrders(true);
  }, [searchValue, page, size]);

  useEffect(() => {
    getOrders();
  }, [showOrders]);

  // useBottomScrollListener(() => {
  //   if (!loading && !!orders.length && !isLast) {
  //     getOrders(false, true);
  //   }
  // });

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  return (
    <>
      <Head
        {...{
          label: 'Заказы',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Controllers
        {...{
          searchInputProps: {
            value: searchValue,
            onChange: setSearchValue,
            placeholder: 'Поиск по номеру заказа',
          },
          name: '',
        }}
      />
      <Styled.FilterContainer>
        <Styled.FilterItem
          key={'filter-active'}
          style={{
            background:
              showOrders === 'ACTIVE' ? colors.primary._100 : colors.black._02,
          }}
          onClick={() => onPressShowOrdersBtn('ACTIVE')}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                showOrders === 'ACTIVE' ? colors.black._00 : colors.black._80,
            }}
          >
            Активные
          </p>
        </Styled.FilterItem>
        <Styled.FilterItem
          key={'filter-completed'}
          style={{
            background:
              showOrders === 'COMPLETED'
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressShowOrdersBtn('COMPLETED')}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                showOrders === 'COMPLETED'
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Завершённые
          </p>
        </Styled.FilterItem>
      </Styled.FilterContainer>
      {loading && page === 1 ? (
        <LoadingContent />
      ) : (
        <Form
          data={orders}
          loading={loading}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
