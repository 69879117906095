import { BorderType } from '#styles.utils';

export enum TableCellAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum TableCellTag {
  td = 'td',
  th = 'th',
  tr = 'tr',
}

export type TableCellProps = Readonly<
  Partial<{
    children: React.ReactNode;
    tag: TableCellTag;
    width: number;
    height: number;
    align: TableCellAlign;
    colSpan: number;
    padding: string;
    border: BorderType;
    onClick: () => void;
    to: string;
  }>
>;
