import { UserResponse } from '#services/user/responses/user.response';

export enum UserStatus {
  Idle = 'idle',
  Pending = 'pending',
  Loading = 'loading',
  Successed = 'successed',
  Failed = 'failed',
}

export default interface UserState {
  status: UserStatus;
  data?: UserResponse;
}
