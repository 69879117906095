import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';


// import { ptr } from '#styles.utils';
// import { colors } from '#styles.variables';

export const Container = styled.div`
    width: 100%;
`;

export const Details = styled.div`
    width: 100%;
    background-color: ${colors.primary._05};
    padding: ${ptr(8)};
    border-radius: ${ptr(8)};
`;