import { Form as Formik } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { Typography } from '#ui-kit';
import { TypographySize, TypographyWeight } from '#ui-kit.types';

export const BackLink = styled(Link)`
  position: absolute;
  left: ${ptr(48)};
  top: ${ptr(48)};
  display: flex;
  align-items: center;
  text-decoration: none;
  opacity: 0.6;
  transition: ${transition.average};

  :hover {
    opacity: 1;
  }
`;

export const BackButtonText = styled(Typography).attrs({
  size: TypographySize.s,
  weight: TypographyWeight.Light,
})`
  margin-top: ${ptr(3)};
  margin-left: ${ptr(4)};
  color: ${colors.black._40};
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const FormWrapper = styled.div`
  width: ${ptr(420)};
  padding: ${ptr(32)};
  box-sizing: border-box;
  margin: ${ptr(32)} 0;
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: ${ptr(38)};
`;

export const Form = styled(Formik)`
  margin-top: ${ptr(24)};

  .input-container:not(:last-child) {
    margin-bottom: ${ptr(20)};
  }

  .form__register-button {
    margin-top: ${ptr(64)};
  }
`;

export const Title = styled(Typography)`
  position: relative;
  text-align: center;
`;

export const Underline = styled.img`
  width: ${ptr(32)};
  position: absolute;
  top: ${ptr(-20)};
`;
