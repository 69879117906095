import { CSSObject } from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import {
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from '#ui-kit.types';

export const wrapperStyles = ({
  disabled,
}: {
  disabled: boolean;
}): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  background: colors.black._00,
  borderRadius: ptr(4),
  opacity: disabled ? 0.4 : 1,
  transition: transition.average,
});

export const labelStyles = (): CSSObject => ({
  position: 'absolute',
  zIndex: 0,
  left: ptr(16),
  fontSize: ptr(TypographySize.m),
  top: ptr(24),
  transition: `top, ${transition.average}`,
  display: 'flex',
  alignItems: 'center',
  color: colors.black._20,
  userSelect: 'none',
  fontWeight: TypographyWeight.Light,
  fontFamily: TypographyFamily.FactorA,
});

export const bottomWrapperStyles = (): CSSObject => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});
