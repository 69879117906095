import React from 'react';

import * as Styled from './styles';

import TableEmptyRow from './TableEmptyRow';
import TableHeader from './TableHeader';
import TableLoadingRow from './TableLoadingRow';
import Pagination from './TablePagination/Paginatiom';
import TableRow from './TableRow';
import { TableProps } from './types/Table.types';

const Table: React.FC<TableProps> = ({
  header,
  rows,
  footer,
  loading,
  preLoading = false,
  currentPage = 1,
  totalPages = 1,
  onPageChange,
}) => (
  <Styled.TableWrapper preLoading={preLoading}>
    <Styled.Table>
      <thead>
        <TableHeader {...header} />
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <TableRow {...{ ...row, key: `tbody-${index}` }} />
        ))}
        <TableEmptyRow {...{ rows, header }} />
      </tbody>
      {footer && (
        <tfoot>
          <TableHeader {...footer} />
        </tfoot>
      )}
      {loading && (
        <tfoot>
          <TableLoadingRow {...{ header }} />
        </tfoot>
      )}
    </Styled.Table>
    {onPageChange && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    )}
  </Styled.TableWrapper>
);

export default Table;
