import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import debug from '#services/debug.service';
import { RestaurantsService } from '#services/restaurants';
import { RestourantsResponse } from '#services/restaurants/responses/restaurants.response';

import { UseRestaurantsReturnProps } from './types/use-restaurants.types';

const useRestaurants = (): UseRestaurantsReturnProps => {
  const restaurantsService = new RestaurantsService();

  const [data, setData] = useState<RestourantsResponse>();
  const [isLast, setIsLast] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [{ generalLoading, loading }, setLoading] = useState({
    generalLoading: true,
    loading: false,
  });

  const getRestaurants = async () => {
    setLoading((old) => ({
      ...old,
      loading: false,
    }));
    try {
      const result = await restaurantsService.getRestaurants({
        page: `${page}`,
        size: '20',
      });

      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }

        return result;
      });

      setIsLast(result.isLast);

      setPage((prevPageNumber) => prevPageNumber + 1);

      setLoading((old) => ({
        ...old,
        generalLoading: false,
        loading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useUsers ~ getUsers ~ err:', err);
    }
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  useBottomScrollListener(() => {
    if (!generalLoading && !loading && !isLast) {
      getRestaurants();
    }
  });

  return [data, { generalLoading, loading }, isLast, getRestaurants];
};

export default useRestaurants;
