import React from 'react';

import * as Styled from './styles';

import { Input, SingleSelect } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

import { ControllersProps } from './types/Controllers.types';

const Controllers: React.FC<ControllersProps> = ({
  searchInputProps,
  filterProps,
  name = 'search',
}) => (
  <Styled.Container>
    <Styled.SearchContainer>
      <Input
        {...{
          ...searchInputProps,
          name,
          icon: IconNames.magnifier,
          clearable: true,
        }}
      />
    </Styled.SearchContainer>
    <Styled.FilterControls>
      {filterProps && (
        <SingleSelect
          {...{
            ...filterProps,
            classNamePrefix: 'filter',
            name: 'filter',
            closeMenuOnSelect: true,
          }}
        />
      )}
    </Styled.FilterControls>
  </Styled.Container>
);

export default Controllers;
