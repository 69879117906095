import { notAvailableValue } from '#utils';

import { SupportMessageType } from '#services/support/responses/support-message.response';

export const formatSupportMessageType = (
  type: SupportMessageType | undefined,
) => {
  switch (type) {
    case SupportMessageType.BAD_SERVICE:
      return 'Плохое обслуживание';

    case SupportMessageType.DISLIKED_FOOD:
      return 'Не понравилась еда';

    case SupportMessageType.DELIVERY_ISSUES:
      return 'Проблемы с доставкой';

    case SupportMessageType.SANITATION_CLEANLINESS:
      return 'Грязно в ресторане';

    case SupportMessageType.APPRECIATION_STAFF:
      return 'Поощрение персонала';

    case SupportMessageType.MENU_UNAVAILABLE:
      return 'Недоступность меню';

    case SupportMessageType.OTHER:
      return 'Другое';

    default:
      notAvailableValue(type);
  }
};
