import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';

import apiService from '#services/api';

import debug from '#debug';

import { AuthDto } from './dto/auth.dto';
import { AuthResponse } from './responses/auth.response';

class AuthService {
  private API_ROUTES = {
    auth: 'auth/login',
  };

  private accessTokenKey = '__kartoshka_user_accessToken';

  private fingerPrintJs: Agent | undefined;

  private clientFingerprint: string | undefined;

  constructor() {
    (async () => {
      this.fingerPrintJs = await FingerprintJS.load();

      const result = await this.fingerPrintJs.get();

      this.clientFingerprint = result.visitorId;
    })();
  }

  public getAccessToken() {
    return Cookies.get(this.accessTokenKey);
  }

  private setAccessToken(accessToken: string) {
    Cookies.set(this.accessTokenKey, accessToken, {
      expires: 2,
      secure: debug.isProduction,
    });
  }

  private removeAccessToken() {
    Cookies.remove(this.accessTokenKey);
  }

  public async auth(data: AuthDto) {
    try {
      const request = await apiService.request.post(this.API_ROUTES.auth, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ ...data, fingerprint: this.clientFingerprint }),
      });

      const result: AuthResponse = await request.json();

      debug.success('🧑🏻‍💻 ~ AuthService ~ auth ~ result:', result);

      this.setAccessToken(result.accessToken);

      return result;
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ AuthService ~ auth ~ err:', err);

      throw err;
    }
  }

  public async logOut() {
    try {
      this.removeAccessToken();

      debug.success('🧑🏻‍💻 ~ AuthService ~ logOut ~ result:', 'success');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ AuthService ~ logOut ~ err:', err);

      throw err;
    }
  }
}

export default AuthService;
