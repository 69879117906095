import * as Styled from './styles';

import { TypographySize } from '#ui-kit.types';

const Footer = () => (
  <Styled.Container>
    <Styled.Copyright size={TypographySize.xs}>
      Copyright © {new Date().getFullYear()}
    </Styled.Copyright>
  </Styled.Container>
);

export default Footer;
