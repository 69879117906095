import styled from 'styled-components';

import { ptr } from '#styles.utils';

export const TableRowWrapper = styled.tr(({}) => ({
  borderBottomWidth: ptr(1),
  borderColor: 'transparent',
  width: '100%',
  overflow: 'hidden',

  ':last-child > td': {
    borderBottom: 0,
  },
}));
