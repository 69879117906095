import { Formik } from 'formik';
import * as Yup from 'yup';

import * as Styled from './styles';

import { Button, InputField, MaskInputField } from '#ui-kit';

import { FormContainer, FormSection } from '#components';

import { formatPhoneNumber, phoneNumberValidationSchema } from '#utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Поле обязательно для заполнения'),
  address: Yup.string().required('Поле обязательно для заполнения'),
  phoneNumber: phoneNumberValidationSchema,
  inn: Yup.string().min(9).max(9).required('Поле обязательно для заполнения'),
});

const Form = () => {
  const initialValues = {
    address: 'г.Москва, ул.Хабарова, д.2, ТЦ "Новомосковский", 3 этаж',
    city: 'Москва',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    inn: '871782817',
    name: 'Новомосковский',
    phoneNumber: formatPhoneNumber('+79207786233'),
  };

  const onSubmit = () => {};

  return (
    <Styled.FormikContainer>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        <FormContainer
          leftSideActions={
            <Button className="create-restaurant__submit" label="Сохранить" />
          }
        >
          <FormSection label="Наименование" isRequired>
            <InputField name="name" placeholder="Введите наименование" />
          </FormSection>
          <FormSection label="Адрес" isRequired>
            <InputField name="address" placeholder="Введите адрес" />
          </FormSection>
          <FormSection label="Номер телефона">
            <MaskInputField
              name="phoneNumber"
              placeholder="Введите номер телефона"
              mask="+7 (999) 999-99-99"
            />
          </FormSection>
          <FormSection label="ИНН" isRequired>
            <MaskInputField
              name="inn"
              placeholder="Введите ИНН"
              mask="999999999"
            />
          </FormSection>
        </FormContainer>
      </Formik>
    </Styled.FormikContainer>
  );
};

export default Form;
