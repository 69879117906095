import { PaginationResponseType } from '../../api/responses/pagination.response';

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export type UserResponse = Readonly<{
  id: number;

  name: string | null;

  birthDate: Date | null;

  sex: Sex | null;

  phoneNumber: string;

  email: string | null;

  isEmailConfirmed: boolean;

  createdAt: Date;

  role: UserRole;

  status: UserStatus;

  createdBy: number | null;

  updatedBy: number | null;

  updatedAt: Date;
}>;

export type UsersResponse = PaginationResponseType<UserResponse>;
