import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';
import styled from 'styled-components';

export const PromoPhoto = styled.img`
  background-color: ${colors.black._02};
  max-height: 200px;
  max-width: 100%;
`;

export const PromoPhotoContainer = styled.div`
  position: relative;
  margin-bottom: ${ptr(18)};
  margin-top: ${ptr(18)};
  width: 375px;
  display: flex;
  justify-content: center;
`;

export const PromoButton = styled.div`
  width: 45%;

  .button {
    height: ${ptr(20)};
  }
`;

export const PromoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PromoItemContainer = styled.div`
  box-shadow: 0 ${ptr(4)} ${ptr(6)} ${colors.black._02};
  border-radius: ${ptr(8)};
  padding: ${ptr(18)};
  background-color: ${colors.black._02};
  width: 375px;
  margin-bottom: ${ptr(36)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
