export enum Breakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1280,
  xxl = 1440,
}

export const colors = {
  error: {
    _100: '#ff0000',
    _10: '#ffe7e7',
  },
  warning: {
    _100: '#FF822E',
    _10: '#FFB685',
  },
  black: {
    _100: '#5F5B59',
    _80: '#8F8A87',
    _40: '#A6A2A0',
    _20: '#BAB7B5',
    _10: '#CECCCA',
    _05: '#E2E0DF',
    _02: '#F5F5F4',
    _00: '#FFFFFF',
  },
  primary: {
    _100: '#8FCB24',
    _80: '#A4DD40',
    _40: '#B4E363',
    _20: '#C5EA86',
    _10: '#D6F0A8',
    _05: '#E6F6CB',
  },
  secondary: {
    _100: '#3C1600',
    _80: '#4e2006',
    _40: '#8F3400',
    _20: '#B84300',
    _10: '#E05200',
    _05: '#FFD8C2',
  },
  blue: {
    _10: '#00ABF0',
  },
};

export const transition = {
  fast: '.04s',
  average: '.2s',
  slow: '1s',
};
