import { useCurrentUser } from '#hooks';

import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import Form from './containers/Form';

export default () => {
  const [{ generalLoading }] = useCurrentUser();

  if (generalLoading) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Профиль',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Form />
    </>
  );
};
