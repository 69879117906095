import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import { Typography } from '#ui-kit';

export const Container = styled.div`
  display: flex;
  padding-top: ${ptr(32)};
  padding-bottom: ${ptr(24)};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: ${ptr(1)} solid ${colors.black._05};
  }
`;

export const Aside = styled.div`
  flex: 1;
  margin-right: ${ptr(24)};
`;

export const Label = styled(Typography)``;

export const Description = styled(Typography)`
  margin-top: ${ptr(16)};
  color: ${colors.black._40};
  line-height: 130%;
`;

export const Content = styled.div`
  flex: 2;
`;

export const Children = styled.div`
  max-width: ${ptr(480)};
  width: 100%;
`;

export const Marker = styled(Typography)``;
