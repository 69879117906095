import React from 'react';

import * as Styled from './styles';

import { TableCellProps } from './types/TableCell.types';

const TableCell: React.FC<TableCellProps> = (props) => (
  <Styled.TableCell as={props.tag as never | undefined} {...{ ...props }}>
    {props.to ? (
      <Styled.LinkedCell to={props.to}>{props.children}</Styled.LinkedCell>
    ) : (
      props.children
    )}
  </Styled.TableCell>
);

export default TableCell;
