import { Head } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import Form from './containers/Form';

export default () => (
  <>
    <Head
      {...{
        label: 'Добавление ресторана',
        underlineVariant: HeadUnderlineVariant.Secondary,
      }}
    />
    <Form />
  </>
);
