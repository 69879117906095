export enum OrderInternalStatusEnum {
  NEW = 'NEW',

  FAILED = 'FAILED',

  IN_PROGRESS = 'IN_PROGRESS',

  CANCELED = 'CANCELED',

  COMPLETED = 'COMPLETED',
}
