import * as Styled from './styles';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { StatusBadge } from '#components';
import { StatusBadgeVariant } from '#components.types';

import { formatPhoneNumber, notAvailableValue } from '#utils';

import { UserStatus } from '#services/user/responses/user.response';

import { TableProps } from './types/Table.types';

const Component: React.FC<TableProps> = ({
  data: { data },
  loading,
  preLoading,
}) => (
  <Table
    {...{
      loading,
      preLoading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Имя пользователя
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Электронная почта
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Номер телефона
              </Typography>
            ),
          },
          4: {
            width: 20,
            align: TableCellAlign.Right,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Статус
              </Typography>
            ),
          },
        },
      },
      rows: data.map(({ id, name, email, phoneNumber, status: value }) => ({
        to: `/users/${id}`,
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(name)}
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(email)}
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Right,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {formatPhoneNumber(notAvailableValue(phoneNumber))}
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Right,
            onClick: () => {},
            children: (
              <Styled.UserStatusContainer>
                <StatusBadge<typeof UserStatus>
                  {...{
                    value,
                    data: {
                      [UserStatus.ACTIVE]: {
                        label: 'Активный',
                        variant: StatusBadgeVariant.Active,
                      },
                      [UserStatus.DISABLED]: {
                        label: 'Деактивирован',
                        variant: StatusBadgeVariant.Warning,
                      },
                      [UserStatus.BLOCKED]: {
                        label: 'Заблокирован',
                        variant: StatusBadgeVariant.Disabled,
                      },
                      [UserStatus.DELETED]: {
                        label: 'Удален',
                        variant: StatusBadgeVariant.Blocked,
                      },
                    },
                  }}
                />
              </Styled.UserStatusContainer>
            ),
          },
        },
      })),
    }}
  />
);

export default Component;
