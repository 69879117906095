import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors, transition } from '#styles.variables';

import { TypographyFamily, TypographySize } from '#ui-kit.types';

export const FilterContainer = styled.div`
  display: flex;
  border-radius: ${ptr(20)};
  border: ${ptr(1)} solid ${colors.black._02};
  height: ${ptr(40)};
  margin-bottom: ${ptr(20)};
  background: ${colors.black._02};
`;

export const FilterItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background ${transition.average} ease-in-out;
  border-radius: ${ptr(4)};

  .filter_item_text {
    font-size: ${TypographySize.m};
    font-family: ${TypographyFamily.FactorA};
  }
  ,
  .filter_item_text:hover {
    cursor: pointer;
  }
`;
