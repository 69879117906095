import { useEffect, useRef } from 'react';

const useDebounceEffect = (
  effect: () => void = () => {},
  dep: React.DependencyList | undefined = [],
  debounce = 500,
  onFirstRender = false,
) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current && !onFirstRender) {
      firstUpdate.current = false;
      return;
    } else {
      const delayDebounceFn = setTimeout(effect, debounce);
      return () => clearTimeout(delayDebounceFn);
    }
  }, dep);
};

export default useDebounceEffect;
