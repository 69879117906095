import { toast, ToastOptions } from 'react-toastify';

class Toastify {
  readonly defaultConfig: ToastOptions = {};

  info(value: string, options: ToastOptions) {
    return toast.info(value, { ...this.defaultConfig, ...options });
  }

  success(value: string) {
    return toast.success(value, this.defaultConfig);
  }

  error(value: string) {
    toast.error(value, this.defaultConfig);
  }
}
const toastify = new Toastify();
export default toastify;
