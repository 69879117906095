import { useEffect, useState } from 'react';

import { useAppDispatch } from '#hooks';

import { userSliceActions } from '#store/user';

import debug from '#services/debug.service';
import { UserService } from '#services/user';

import { UseCurrentUserReturnProps } from './types/use-current-user.types';

const useCurrentUser = (): UseCurrentUserReturnProps => {
  const userService = new UserService();

  const dispatch = useAppDispatch();

  const [{ generalLoading }, setLoading] = useState({
    generalLoading: true,
  });

  const getUser = async () => {
    try {
      const result = await userService.getCurrentUser();

      dispatch(userSliceActions.restoreUserData(result));

      setLoading((old) => ({
        ...old,
        generalLoading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useCurrentUser ~ getUser ~ err:', err);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return [{ generalLoading }];
};

export default useCurrentUser;
