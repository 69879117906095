import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import { useField } from 'formik';
import ReactDatePicker from 'react-datepicker';

import * as Styled from '../input.styles';
import { DatePickerWrapper } from './styles';

import Error from '../../common/Error';
import { DateInputFieldProps, DateInputProps } from './types/DateInput.types';

const CustomInput: React.FC<
  Omit<DateInputProps, 'placeholder' | 'onChange'> &
    Partial<{
      placeHolder: string;
      onClick?: () => void;
      onFocus: () => void;
      onBlur: () => void;
    }>
> = React.forwardRef(
  (
    {
      parentClassName,
      disabled = false,
      readOnly = false,
      className,
      label = '',
      name,
      error,
      touched,
      onClick,
      value,
      placeHolder: placeholder,
      onFocus,
      onBlur,
    },
    ref,
  ) => (
    <Styled.Container
      {...{ className: cn('input-container', parentClassName) }}
    >
      <Styled.Wrapper {...{ disabled, className: cn('input', className) }}>
        <Styled.Input
          {...{
            ref: ref as React.RefObject<HTMLInputElement>,
            placeholder,
            name,
            value: `${value}`,
            label,
            disabled,
            readOnly,
            onClick,
            onFocus,
            onBlur,
          }}
        />
        <Styled.Label>{label}</Styled.Label>
      </Styled.Wrapper>
      <Styled.BottomWrapper>
        <Error {...{ error, touched }} />
      </Styled.BottomWrapper>
    </Styled.Container>
  ),
);

const DateInput: React.FC<DateInputProps> = React.memo(
  ({
    value: selected,
    placeholder,
    minDate = new Date(new Date().setFullYear(1950)),
    clearable: isClearable,
    readOnly = false,
    onChange,
    ...props
  }) => {
    const [focused, setFocused] = useState(false);

    return (
      <DatePickerWrapper {...{ focused }}>
        <ReactDatePicker
          {...{
            locale: ruLocale,
            selected,
            disabledKeyboardNavigation: true,
            showMonthDropdown: true,
            showYearDropdown: true,
            dropdownMode: 'select',
            isClearable: isClearable && !readOnly,
            minDate,
            readOnly,
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
            customInput: (
              <CustomInput
                {...{
                  value: selected,
                  placeHolder: placeholder,
                  ...props,
                }}
              />
            ),
            onChange: (date) => {
              setFocused(false);
              onChange(date);
            },
            ...props,
          }}
        />
      </DatePickerWrapper>
    );
  },
);

const DateInputField: React.FC<DateInputFieldProps> = React.memo((props) => {
  const [{ value: initialValue }, { error, touched }, { setValue }] =
    useField<Date | null>(props.name);

  const [value, onChange] = useState(initialValue);

  useEffect(() => {
    setValue(value, true);
  }, [value]);

  return (
    <DateInput
      {...{
        ...props,
        value,
        onChange,
        error,
        touched,
      }}
    />
  );
});

export { DateInputField };

export default DateInput;
