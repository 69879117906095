import styled from 'styled-components';

import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Container = styled.div`
  background-color: ${colors.black._00};
  border-style: solid;
  border-color: ${colors.primary._10};
  border-width: ${ptr(3)};
  border-radius: ${ptr(8)};
  width: ${ptr(700)};
  margin: auto;
  margin-bottom: ${ptr(50)};
  padding: ${ptr(20)}
`;

export const Title = styled.h3`
  width: 100%;
  text-align: center;
`;

export const Receipt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PricesContainer = styled.div`
  padding: ${ptr(10)};
  width: 50%;
  margin-top: ${ptr(20)};
  margin-left: auto;
  background-color: ${colors.primary._05};
  border-radius: ${ptr(8)};
`;

export const PriceLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Checkbox = styled.input`
  accent-color: ${colors.primary._100};
  color: ${colors.black._00}
`;
