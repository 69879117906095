export enum SwitcherType {
  Linked = 'linked',
  Default = 'default',
}

export type SwitcherElementProps = Readonly<{
  to?: string;
  id: string;
  label: string;
}>;

export type SwitcherProps = Readonly<
  Partial<{
    type: SwitcherType;
    activeId: string;
    onClick: (T: string) => void;
    data: [SwitcherElementProps, SwitcherElementProps];
  }>
>;
