import { useLocation } from 'react-router-dom';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import {
  SwitcherElementProps,
  SwitcherProps,
  SwitcherType,
} from './types/Swithcer.types';

const SwitchButton: React.FC<
  Omit<SwitcherElementProps, 'to'> &
    Pick<SwitcherProps, 'onClick'> & { isActive: boolean }
> = ({ label, isActive, onClick = () => {}, id }) => (
  <Styled.SwitchButton
    {...{ isActive, type: 'button', onClick: () => onClick(id) }}
  >
    <Styled.Label color={isActive ? colors.black._00 : colors.secondary._100}>
      {label}
    </Styled.Label>
  </Styled.SwitchButton>
);

const SwitchLink: React.FC<SwitcherElementProps & { isActive: boolean }> = ({
  label,
  to = '',
  isActive,
}) => (
  <Styled.SwitchLink {...{ to, isActive }}>
    <Styled.Label color={isActive ? colors.black._00 : colors.secondary._100}>
      {label}
    </Styled.Label>
  </Styled.SwitchLink>
);

const SwitchReducer: React.FC<
  SwitcherElementProps & Omit<SwitcherProps, 'data'>
> = ({ type, activeId, onClick, ...rest }) => {
  const { pathname } = useLocation();

  const key = `${type}-switcher-${rest.id}`;

  if (type === SwitcherType.Linked) {
    return <SwitchLink {...{ ...rest, key, isActive: pathname === rest.to }} />;
  }

  return (
    <SwitchButton
      {...{ ...rest, key, onClick, isActive: activeId === rest.id }}
    />
  );
};

const DefaultSwitcher: React.FC<SwitcherProps> = ({
  data,
  type,
  activeId,
  onClick,
}) => (
  <Styled.Container>
    {data?.map((props) => (
      <SwitchReducer {...{ ...props, type, activeId, onClick }} />
    ))}
  </Styled.Container>
);

export default DefaultSwitcher;
