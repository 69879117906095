import { colors } from '#styles.variables';

import { UserStatus } from '#services/user/responses/user.response';

export const usersStatusOptions = [
  {
    value: UserStatus.ACTIVE,
    label: 'Активен',
    color: colors.primary._100,
  },
  {
    value: UserStatus.BLOCKED,
    label: 'Заблокирован',
    color: colors.black._80,
  },
  {
    value: UserStatus.DELETED,
    label: 'Удален',
    color: colors.error._100,
  },
  {
    value: UserStatus.DISABLED,
    label: 'Деактивирован',
    color: colors.warning._100,
  },
];

export const statusOptions = [
  {
    value: UserStatus.ACTIVE,
    label: 'Активен',
    color: colors.primary._100,
  },
  {
    value: UserStatus.BLOCKED,
    label: 'Заблокирован',
    color: colors.black._80,
  },
  {
    value: UserStatus.DELETED,
    label: 'Удален',
    color: colors.error._100,
  },
  {
    value: UserStatus.DISABLED,
    label: 'Деактивирован',
    color: colors.warning._100,
  },
];
