import styled from 'styled-components';

import { ptr } from '#styles.utils';

export const UserStatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .status-badge {
    min-width: ${ptr(140)};
  }
`;
