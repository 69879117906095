import React from 'react';

import cn from 'classnames';

import * as Styled from './styles';

import {
  LogotypeProps,
  LogotypeSize,
  LogotypeVariant,
} from './types/Logotype.types';

const PRIMARY_LOGO = '/assets/images/logotypes/logo(2).png';

const SECONDARY_LOGO = '/assets/images/logotypes/logo(1).png';

const LOGOTYPE_SIZES = {
  [LogotypeVariant.Primary]: {
    [LogotypeSize.Large]: {
      width: 180,
      height: 66,
    },
    [LogotypeSize.Medium]: {
      width: 160,
      height: 58,
    },
    [LogotypeSize.Small]: {
      width: 147,
      height: 53,
    },
  },
  [LogotypeVariant.Secondary]: {
    [LogotypeSize.Large]: {
      width: 66,
      height: 66,
    },
    [LogotypeSize.Medium]: {
      width: 58,
      height: 58,
    },
    [LogotypeSize.Small]: {
      width: 53,
      height: 53,
    },
  },
};

const Logotype: React.FC<LogotypeProps> = ({
  isLink = false,
  variant = LogotypeVariant.Primary,
  size = LogotypeSize.Medium,
  className = '',
}) => {
  if (isLink) {
    return (
      <Styled.LogotypeLink
        to="/"
        className={cn('logotype linked-logotype', className)}
      >
        <Styled.Logotype
          {...{
            src:
              variant === LogotypeVariant.Secondary
                ? SECONDARY_LOGO
                : PRIMARY_LOGO,
            ...LOGOTYPE_SIZES[variant][size],
          }}
        />
      </Styled.LogotypeLink>
    );
  }

  return (
    <Styled.LogotypeContainer className={cn('logotype', className)}>
      <Styled.Logotype
        {...{
          src:
            variant === LogotypeVariant.Secondary
              ? SECONDARY_LOGO
              : PRIMARY_LOGO,
          ...LOGOTYPE_SIZES[variant][size],
        }}
      />
    </Styled.LogotypeContainer>
  );
};

export default Logotype;
